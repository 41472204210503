/* eslint-disable */
import * as Long from 'long'
import { util, configure, Writer, Reader } from 'protobufjs/minimal'
import { Height } from '../../../../ibc/core/client/v1/client'

export const protobufPackage = 'ibc.core.channel.v1'

/**
 * State defines if a channel is in one of the following states:
 * CLOSED, INIT, TRYOPEN, OPEN or UNINITIALIZED.
 */
export enum State {
  /** STATE_UNINITIALIZED_UNSPECIFIED - Default State */
  STATE_UNINITIALIZED_UNSPECIFIED = 0,
  /** STATE_INIT - A channel has just started the opening handshake. */
  STATE_INIT = 1,
  /** STATE_TRYOPEN - A channel has acknowledged the handshake step on the counterparty chain. */
  STATE_TRYOPEN = 2,
  /**
   * STATE_OPEN - A channel has completed the handshake. Open channels are
   * ready to send and receive packets.
   */
  STATE_OPEN = 3,
  /**
   * STATE_CLOSED - A channel has been closed and can no longer be used to send or receive
   * packets.
   */
  STATE_CLOSED = 4,
  UNRECOGNIZED = -1,
}

export function stateFromJSON(object: any): State {
  switch (object) {
    case 0:
    case 'STATE_UNINITIALIZED_UNSPECIFIED':
      return State.STATE_UNINITIALIZED_UNSPECIFIED
    case 1:
    case 'STATE_INIT':
      return State.STATE_INIT
    case 2:
    case 'STATE_TRYOPEN':
      return State.STATE_TRYOPEN
    case 3:
    case 'STATE_OPEN':
      return State.STATE_OPEN
    case 4:
    case 'STATE_CLOSED':
      return State.STATE_CLOSED
    case -1:
    case 'UNRECOGNIZED':
    default:
      return State.UNRECOGNIZED
  }
}

export function stateToJSON(object: State): string {
  switch (object) {
    case State.STATE_UNINITIALIZED_UNSPECIFIED:
      return 'STATE_UNINITIALIZED_UNSPECIFIED'
    case State.STATE_INIT:
      return 'STATE_INIT'
    case State.STATE_TRYOPEN:
      return 'STATE_TRYOPEN'
    case State.STATE_OPEN:
      return 'STATE_OPEN'
    case State.STATE_CLOSED:
      return 'STATE_CLOSED'
    default:
      return 'UNKNOWN'
  }
}

/** Order defines if a channel is ORDERED or UNORDERED */
export enum Order {
  /** ORDER_NONE_UNSPECIFIED - zero-value for channel ordering */
  ORDER_NONE_UNSPECIFIED = 0,
  /**
   * ORDER_UNORDERED - packets can be delivered in any order, which may differ from the order in
   * which they were sent.
   */
  ORDER_UNORDERED = 1,
  /** ORDER_ORDERED - packets are delivered exactly in the order which they were sent */
  ORDER_ORDERED = 2,
  UNRECOGNIZED = -1,
}

export function orderFromJSON(object: any): Order {
  switch (object) {
    case 0:
    case 'ORDER_NONE_UNSPECIFIED':
      return Order.ORDER_NONE_UNSPECIFIED
    case 1:
    case 'ORDER_UNORDERED':
      return Order.ORDER_UNORDERED
    case 2:
    case 'ORDER_ORDERED':
      return Order.ORDER_ORDERED
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Order.UNRECOGNIZED
  }
}

export function orderToJSON(object: Order): string {
  switch (object) {
    case Order.ORDER_NONE_UNSPECIFIED:
      return 'ORDER_NONE_UNSPECIFIED'
    case Order.ORDER_UNORDERED:
      return 'ORDER_UNORDERED'
    case Order.ORDER_ORDERED:
      return 'ORDER_ORDERED'
    default:
      return 'UNKNOWN'
  }
}

/**
 * Channel defines pipeline for exactly-once packet delivery between specific
 * modules on separate blockchains, which has at least one end capable of
 * sending packets and one end capable of receiving packets.
 */
export interface Channel {
  /** current state of the channel end */
  state: State
  /** whether the channel is ordered or unordered */
  ordering: Order
  /** counterparty channel end */
  counterparty: Counterparty | undefined
  /**
   * list of connection identifiers, in order, along which packets sent on
   * this channel will travel
   */
  connection_hops: string[]
  /** opaque channel version, which is agreed upon during the handshake */
  version: string
}

/**
 * IdentifiedChannel defines a channel with additional port and channel
 * identifier fields.
 */
export interface IdentifiedChannel {
  /** current state of the channel end */
  state: State
  /** whether the channel is ordered or unordered */
  ordering: Order
  /** counterparty channel end */
  counterparty: Counterparty | undefined
  /**
   * list of connection identifiers, in order, along which packets sent on
   * this channel will travel
   */
  connection_hops: string[]
  /** opaque channel version, which is agreed upon during the handshake */
  version: string
  /** port identifier */
  port_id: string
  /** channel identifier */
  channel_id: string
}

/** Counterparty defines a channel end counterparty */
export interface Counterparty {
  /** port on the counterparty chain which owns the other end of the channel. */
  port_id: string
  /** channel end on the counterparty chain */
  channel_id: string
}

/** Packet defines a type that carries data across different chains through IBC */
export interface Packet {
  /**
   * number corresponds to the order of sends and receives, where a Packet
   * with an earlier sequence number must be sent and received before a Packet
   * with a later sequence number.
   */
  sequence: number
  /** identifies the port on the sending chain. */
  source_port: string
  /** identifies the channel end on the sending chain. */
  source_channel: string
  /** identifies the port on the receiving chain. */
  destination_port: string
  /** identifies the channel end on the receiving chain. */
  destination_channel: string
  /** actual opaque bytes transferred directly to the application module */
  data: Uint8Array
  /** block height after which the packet times out */
  timeout_height: Height | undefined
  /** block timestamp (in nanoseconds) after which the packet times out */
  timeout_timestamp: number
}

/**
 * PacketState defines the generic type necessary to retrieve and store
 * packet commitments, acknowledgements, and receipts.
 * Caller is responsible for knowing the context necessary to interpret this
 * state as a commitment, acknowledgement, or a receipt.
 */
export interface PacketState {
  /** channel port identifier. */
  port_id: string
  /** channel unique identifier. */
  channel_id: string
  /** packet sequence. */
  sequence: number
  /** embedded data that represents packet state. */
  data: Uint8Array
}

/**
 * PacketId is an identifer for a unique Packet
 * Source chains refer to packets by source port/channel
 * Destination chains refer to packets by destination port/channel
 */
export interface PacketId {
  /** channel port identifier */
  port_id: string
  /** channel unique identifier */
  channel_id: string
  /** packet sequence */
  sequence: number
}

/**
 * Acknowledgement is the recommended acknowledgement format to be used by
 * app-specific protocols.
 * NOTE: The field numbers 21 and 22 were explicitly chosen to avoid accidental
 * conflicts with other protobuf message formats used for acknowledgements.
 * The first byte of any message with this format will be the non-ASCII values
 * `0xaa` (result) or `0xb2` (error). Implemented as defined by ICS:
 * https://github.com/cosmos/ibc/tree/master/spec/core/ics-004-channel-and-packet-semantics#acknowledgement-envelope
 */
export interface Acknowledgement {
  result: Uint8Array | undefined
  error: string | undefined
}

const baseChannel: object = {
  state: 0,
  ordering: 0,
  connection_hops: '',
  version: '',
}

export const Channel = {
  encode(message: Channel, writer: Writer = Writer.create()): Writer {
    if (message.state !== 0) {
      writer.uint32(8).int32(message.state)
    }
    if (message.ordering !== 0) {
      writer.uint32(16).int32(message.ordering)
    }
    if (message.counterparty !== undefined) {
      Counterparty.encode(
        message.counterparty,
        writer.uint32(26).fork()
      ).ldelim()
    }
    for (const v of message.connection_hops) {
      writer.uint32(34).string(v!)
    }
    if (message.version !== '') {
      writer.uint32(42).string(message.version)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): Channel {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseChannel } as Channel
    message.connection_hops = []
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.state = reader.int32() as any
          break
        case 2:
          message.ordering = reader.int32() as any
          break
        case 3:
          message.counterparty = Counterparty.decode(reader, reader.uint32())
          break
        case 4:
          message.connection_hops.push(reader.string())
          break
        case 5:
          message.version = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Channel {
    const message = { ...baseChannel } as Channel
    message.connection_hops = []
    if (object.state !== undefined && object.state !== null) {
      message.state = stateFromJSON(object.state)
    } else {
      message.state = 0
    }
    if (object.ordering !== undefined && object.ordering !== null) {
      message.ordering = orderFromJSON(object.ordering)
    } else {
      message.ordering = 0
    }
    if (object.counterparty !== undefined && object.counterparty !== null) {
      message.counterparty = Counterparty.fromJSON(object.counterparty)
    } else {
      message.counterparty = undefined
    }
    if (
      object.connection_hops !== undefined &&
      object.connection_hops !== null
    ) {
      for (const e of object.connection_hops) {
        message.connection_hops.push(String(e))
      }
    }
    if (object.version !== undefined && object.version !== null) {
      message.version = String(object.version)
    } else {
      message.version = ''
    }
    return message
  },

  toJSON(message: Channel): unknown {
    const obj: any = {}
    message.state !== undefined && (obj.state = stateToJSON(message.state))
    message.ordering !== undefined &&
      (obj.ordering = orderToJSON(message.ordering))
    message.counterparty !== undefined &&
      (obj.counterparty = message.counterparty
        ? Counterparty.toJSON(message.counterparty)
        : undefined)
    if (message.connection_hops) {
      obj.connection_hops = message.connection_hops.map((e) => e)
    } else {
      obj.connection_hops = []
    }
    message.version !== undefined && (obj.version = message.version)
    return obj
  },

  fromPartial(object: DeepPartial<Channel>): Channel {
    const message = { ...baseChannel } as Channel
    message.connection_hops = []
    if (object.state !== undefined && object.state !== null) {
      message.state = object.state
    } else {
      message.state = 0
    }
    if (object.ordering !== undefined && object.ordering !== null) {
      message.ordering = object.ordering
    } else {
      message.ordering = 0
    }
    if (object.counterparty !== undefined && object.counterparty !== null) {
      message.counterparty = Counterparty.fromPartial(object.counterparty)
    } else {
      message.counterparty = undefined
    }
    if (
      object.connection_hops !== undefined &&
      object.connection_hops !== null
    ) {
      for (const e of object.connection_hops) {
        message.connection_hops.push(e)
      }
    }
    if (object.version !== undefined && object.version !== null) {
      message.version = object.version
    } else {
      message.version = ''
    }
    return message
  },
}

const baseIdentifiedChannel: object = {
  state: 0,
  ordering: 0,
  connection_hops: '',
  version: '',
  port_id: '',
  channel_id: '',
}

export const IdentifiedChannel = {
  encode(message: IdentifiedChannel, writer: Writer = Writer.create()): Writer {
    if (message.state !== 0) {
      writer.uint32(8).int32(message.state)
    }
    if (message.ordering !== 0) {
      writer.uint32(16).int32(message.ordering)
    }
    if (message.counterparty !== undefined) {
      Counterparty.encode(
        message.counterparty,
        writer.uint32(26).fork()
      ).ldelim()
    }
    for (const v of message.connection_hops) {
      writer.uint32(34).string(v!)
    }
    if (message.version !== '') {
      writer.uint32(42).string(message.version)
    }
    if (message.port_id !== '') {
      writer.uint32(50).string(message.port_id)
    }
    if (message.channel_id !== '') {
      writer.uint32(58).string(message.channel_id)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): IdentifiedChannel {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseIdentifiedChannel } as IdentifiedChannel
    message.connection_hops = []
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.state = reader.int32() as any
          break
        case 2:
          message.ordering = reader.int32() as any
          break
        case 3:
          message.counterparty = Counterparty.decode(reader, reader.uint32())
          break
        case 4:
          message.connection_hops.push(reader.string())
          break
        case 5:
          message.version = reader.string()
          break
        case 6:
          message.port_id = reader.string()
          break
        case 7:
          message.channel_id = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): IdentifiedChannel {
    const message = { ...baseIdentifiedChannel } as IdentifiedChannel
    message.connection_hops = []
    if (object.state !== undefined && object.state !== null) {
      message.state = stateFromJSON(object.state)
    } else {
      message.state = 0
    }
    if (object.ordering !== undefined && object.ordering !== null) {
      message.ordering = orderFromJSON(object.ordering)
    } else {
      message.ordering = 0
    }
    if (object.counterparty !== undefined && object.counterparty !== null) {
      message.counterparty = Counterparty.fromJSON(object.counterparty)
    } else {
      message.counterparty = undefined
    }
    if (
      object.connection_hops !== undefined &&
      object.connection_hops !== null
    ) {
      for (const e of object.connection_hops) {
        message.connection_hops.push(String(e))
      }
    }
    if (object.version !== undefined && object.version !== null) {
      message.version = String(object.version)
    } else {
      message.version = ''
    }
    if (object.port_id !== undefined && object.port_id !== null) {
      message.port_id = String(object.port_id)
    } else {
      message.port_id = ''
    }
    if (object.channel_id !== undefined && object.channel_id !== null) {
      message.channel_id = String(object.channel_id)
    } else {
      message.channel_id = ''
    }
    return message
  },

  toJSON(message: IdentifiedChannel): unknown {
    const obj: any = {}
    message.state !== undefined && (obj.state = stateToJSON(message.state))
    message.ordering !== undefined &&
      (obj.ordering = orderToJSON(message.ordering))
    message.counterparty !== undefined &&
      (obj.counterparty = message.counterparty
        ? Counterparty.toJSON(message.counterparty)
        : undefined)
    if (message.connection_hops) {
      obj.connection_hops = message.connection_hops.map((e) => e)
    } else {
      obj.connection_hops = []
    }
    message.version !== undefined && (obj.version = message.version)
    message.port_id !== undefined && (obj.port_id = message.port_id)
    message.channel_id !== undefined && (obj.channel_id = message.channel_id)
    return obj
  },

  fromPartial(object: DeepPartial<IdentifiedChannel>): IdentifiedChannel {
    const message = { ...baseIdentifiedChannel } as IdentifiedChannel
    message.connection_hops = []
    if (object.state !== undefined && object.state !== null) {
      message.state = object.state
    } else {
      message.state = 0
    }
    if (object.ordering !== undefined && object.ordering !== null) {
      message.ordering = object.ordering
    } else {
      message.ordering = 0
    }
    if (object.counterparty !== undefined && object.counterparty !== null) {
      message.counterparty = Counterparty.fromPartial(object.counterparty)
    } else {
      message.counterparty = undefined
    }
    if (
      object.connection_hops !== undefined &&
      object.connection_hops !== null
    ) {
      for (const e of object.connection_hops) {
        message.connection_hops.push(e)
      }
    }
    if (object.version !== undefined && object.version !== null) {
      message.version = object.version
    } else {
      message.version = ''
    }
    if (object.port_id !== undefined && object.port_id !== null) {
      message.port_id = object.port_id
    } else {
      message.port_id = ''
    }
    if (object.channel_id !== undefined && object.channel_id !== null) {
      message.channel_id = object.channel_id
    } else {
      message.channel_id = ''
    }
    return message
  },
}

const baseCounterparty: object = { port_id: '', channel_id: '' }

export const Counterparty = {
  encode(message: Counterparty, writer: Writer = Writer.create()): Writer {
    if (message.port_id !== '') {
      writer.uint32(10).string(message.port_id)
    }
    if (message.channel_id !== '') {
      writer.uint32(18).string(message.channel_id)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): Counterparty {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseCounterparty } as Counterparty
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.port_id = reader.string()
          break
        case 2:
          message.channel_id = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Counterparty {
    const message = { ...baseCounterparty } as Counterparty
    if (object.port_id !== undefined && object.port_id !== null) {
      message.port_id = String(object.port_id)
    } else {
      message.port_id = ''
    }
    if (object.channel_id !== undefined && object.channel_id !== null) {
      message.channel_id = String(object.channel_id)
    } else {
      message.channel_id = ''
    }
    return message
  },

  toJSON(message: Counterparty): unknown {
    const obj: any = {}
    message.port_id !== undefined && (obj.port_id = message.port_id)
    message.channel_id !== undefined && (obj.channel_id = message.channel_id)
    return obj
  },

  fromPartial(object: DeepPartial<Counterparty>): Counterparty {
    const message = { ...baseCounterparty } as Counterparty
    if (object.port_id !== undefined && object.port_id !== null) {
      message.port_id = object.port_id
    } else {
      message.port_id = ''
    }
    if (object.channel_id !== undefined && object.channel_id !== null) {
      message.channel_id = object.channel_id
    } else {
      message.channel_id = ''
    }
    return message
  },
}

const basePacket: object = {
  sequence: 0,
  source_port: '',
  source_channel: '',
  destination_port: '',
  destination_channel: '',
  timeout_timestamp: 0,
}

export const Packet = {
  encode(message: Packet, writer: Writer = Writer.create()): Writer {
    if (message.sequence !== 0) {
      writer.uint32(8).uint64(message.sequence)
    }
    if (message.source_port !== '') {
      writer.uint32(18).string(message.source_port)
    }
    if (message.source_channel !== '') {
      writer.uint32(26).string(message.source_channel)
    }
    if (message.destination_port !== '') {
      writer.uint32(34).string(message.destination_port)
    }
    if (message.destination_channel !== '') {
      writer.uint32(42).string(message.destination_channel)
    }
    if (message.data.length !== 0) {
      writer.uint32(50).bytes(message.data)
    }
    if (message.timeout_height !== undefined) {
      Height.encode(message.timeout_height, writer.uint32(58).fork()).ldelim()
    }
    if (message.timeout_timestamp !== 0) {
      writer.uint32(64).uint64(message.timeout_timestamp)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): Packet {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...basePacket } as Packet
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.sequence = longToNumber(reader.uint64() as Long)
          break
        case 2:
          message.source_port = reader.string()
          break
        case 3:
          message.source_channel = reader.string()
          break
        case 4:
          message.destination_port = reader.string()
          break
        case 5:
          message.destination_channel = reader.string()
          break
        case 6:
          message.data = reader.bytes()
          break
        case 7:
          message.timeout_height = Height.decode(reader, reader.uint32())
          break
        case 8:
          message.timeout_timestamp = longToNumber(reader.uint64() as Long)
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Packet {
    const message = { ...basePacket } as Packet
    if (object.sequence !== undefined && object.sequence !== null) {
      message.sequence = Number(object.sequence)
    } else {
      message.sequence = 0
    }
    if (object.source_port !== undefined && object.source_port !== null) {
      message.source_port = String(object.source_port)
    } else {
      message.source_port = ''
    }
    if (object.source_channel !== undefined && object.source_channel !== null) {
      message.source_channel = String(object.source_channel)
    } else {
      message.source_channel = ''
    }
    if (
      object.destination_port !== undefined &&
      object.destination_port !== null
    ) {
      message.destination_port = String(object.destination_port)
    } else {
      message.destination_port = ''
    }
    if (
      object.destination_channel !== undefined &&
      object.destination_channel !== null
    ) {
      message.destination_channel = String(object.destination_channel)
    } else {
      message.destination_channel = ''
    }
    if (object.data !== undefined && object.data !== null) {
      message.data = bytesFromBase64(object.data)
    }
    if (object.timeout_height !== undefined && object.timeout_height !== null) {
      message.timeout_height = Height.fromJSON(object.timeout_height)
    } else {
      message.timeout_height = undefined
    }
    if (
      object.timeout_timestamp !== undefined &&
      object.timeout_timestamp !== null
    ) {
      message.timeout_timestamp = Number(object.timeout_timestamp)
    } else {
      message.timeout_timestamp = 0
    }
    return message
  },

  toJSON(message: Packet): unknown {
    const obj: any = {}
    message.sequence !== undefined && (obj.sequence = message.sequence)
    message.source_port !== undefined && (obj.source_port = message.source_port)
    message.source_channel !== undefined &&
      (obj.source_channel = message.source_channel)
    message.destination_port !== undefined &&
      (obj.destination_port = message.destination_port)
    message.destination_channel !== undefined &&
      (obj.destination_channel = message.destination_channel)
    message.data !== undefined &&
      (obj.data = base64FromBytes(
        message.data !== undefined ? message.data : new Uint8Array()
      ))
    message.timeout_height !== undefined &&
      (obj.timeout_height = message.timeout_height
        ? Height.toJSON(message.timeout_height)
        : undefined)
    message.timeout_timestamp !== undefined &&
      (obj.timeout_timestamp = message.timeout_timestamp)
    return obj
  },

  fromPartial(object: DeepPartial<Packet>): Packet {
    const message = { ...basePacket } as Packet
    if (object.sequence !== undefined && object.sequence !== null) {
      message.sequence = object.sequence
    } else {
      message.sequence = 0
    }
    if (object.source_port !== undefined && object.source_port !== null) {
      message.source_port = object.source_port
    } else {
      message.source_port = ''
    }
    if (object.source_channel !== undefined && object.source_channel !== null) {
      message.source_channel = object.source_channel
    } else {
      message.source_channel = ''
    }
    if (
      object.destination_port !== undefined &&
      object.destination_port !== null
    ) {
      message.destination_port = object.destination_port
    } else {
      message.destination_port = ''
    }
    if (
      object.destination_channel !== undefined &&
      object.destination_channel !== null
    ) {
      message.destination_channel = object.destination_channel
    } else {
      message.destination_channel = ''
    }
    if (object.data !== undefined && object.data !== null) {
      message.data = object.data
    } else {
      message.data = new Uint8Array()
    }
    if (object.timeout_height !== undefined && object.timeout_height !== null) {
      message.timeout_height = Height.fromPartial(object.timeout_height)
    } else {
      message.timeout_height = undefined
    }
    if (
      object.timeout_timestamp !== undefined &&
      object.timeout_timestamp !== null
    ) {
      message.timeout_timestamp = object.timeout_timestamp
    } else {
      message.timeout_timestamp = 0
    }
    return message
  },
}

const basePacketState: object = { port_id: '', channel_id: '', sequence: 0 }

export const PacketState = {
  encode(message: PacketState, writer: Writer = Writer.create()): Writer {
    if (message.port_id !== '') {
      writer.uint32(10).string(message.port_id)
    }
    if (message.channel_id !== '') {
      writer.uint32(18).string(message.channel_id)
    }
    if (message.sequence !== 0) {
      writer.uint32(24).uint64(message.sequence)
    }
    if (message.data.length !== 0) {
      writer.uint32(34).bytes(message.data)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): PacketState {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...basePacketState } as PacketState
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.port_id = reader.string()
          break
        case 2:
          message.channel_id = reader.string()
          break
        case 3:
          message.sequence = longToNumber(reader.uint64() as Long)
          break
        case 4:
          message.data = reader.bytes()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): PacketState {
    const message = { ...basePacketState } as PacketState
    if (object.port_id !== undefined && object.port_id !== null) {
      message.port_id = String(object.port_id)
    } else {
      message.port_id = ''
    }
    if (object.channel_id !== undefined && object.channel_id !== null) {
      message.channel_id = String(object.channel_id)
    } else {
      message.channel_id = ''
    }
    if (object.sequence !== undefined && object.sequence !== null) {
      message.sequence = Number(object.sequence)
    } else {
      message.sequence = 0
    }
    if (object.data !== undefined && object.data !== null) {
      message.data = bytesFromBase64(object.data)
    }
    return message
  },

  toJSON(message: PacketState): unknown {
    const obj: any = {}
    message.port_id !== undefined && (obj.port_id = message.port_id)
    message.channel_id !== undefined && (obj.channel_id = message.channel_id)
    message.sequence !== undefined && (obj.sequence = message.sequence)
    message.data !== undefined &&
      (obj.data = base64FromBytes(
        message.data !== undefined ? message.data : new Uint8Array()
      ))
    return obj
  },

  fromPartial(object: DeepPartial<PacketState>): PacketState {
    const message = { ...basePacketState } as PacketState
    if (object.port_id !== undefined && object.port_id !== null) {
      message.port_id = object.port_id
    } else {
      message.port_id = ''
    }
    if (object.channel_id !== undefined && object.channel_id !== null) {
      message.channel_id = object.channel_id
    } else {
      message.channel_id = ''
    }
    if (object.sequence !== undefined && object.sequence !== null) {
      message.sequence = object.sequence
    } else {
      message.sequence = 0
    }
    if (object.data !== undefined && object.data !== null) {
      message.data = object.data
    } else {
      message.data = new Uint8Array()
    }
    return message
  },
}

const basePacketId: object = { port_id: '', channel_id: '', sequence: 0 }

export const PacketId = {
  encode(message: PacketId, writer: Writer = Writer.create()): Writer {
    if (message.port_id !== '') {
      writer.uint32(10).string(message.port_id)
    }
    if (message.channel_id !== '') {
      writer.uint32(18).string(message.channel_id)
    }
    if (message.sequence !== 0) {
      writer.uint32(24).uint64(message.sequence)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): PacketId {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...basePacketId } as PacketId
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.port_id = reader.string()
          break
        case 2:
          message.channel_id = reader.string()
          break
        case 3:
          message.sequence = longToNumber(reader.uint64() as Long)
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): PacketId {
    const message = { ...basePacketId } as PacketId
    if (object.port_id !== undefined && object.port_id !== null) {
      message.port_id = String(object.port_id)
    } else {
      message.port_id = ''
    }
    if (object.channel_id !== undefined && object.channel_id !== null) {
      message.channel_id = String(object.channel_id)
    } else {
      message.channel_id = ''
    }
    if (object.sequence !== undefined && object.sequence !== null) {
      message.sequence = Number(object.sequence)
    } else {
      message.sequence = 0
    }
    return message
  },

  toJSON(message: PacketId): unknown {
    const obj: any = {}
    message.port_id !== undefined && (obj.port_id = message.port_id)
    message.channel_id !== undefined && (obj.channel_id = message.channel_id)
    message.sequence !== undefined && (obj.sequence = message.sequence)
    return obj
  },

  fromPartial(object: DeepPartial<PacketId>): PacketId {
    const message = { ...basePacketId } as PacketId
    if (object.port_id !== undefined && object.port_id !== null) {
      message.port_id = object.port_id
    } else {
      message.port_id = ''
    }
    if (object.channel_id !== undefined && object.channel_id !== null) {
      message.channel_id = object.channel_id
    } else {
      message.channel_id = ''
    }
    if (object.sequence !== undefined && object.sequence !== null) {
      message.sequence = object.sequence
    } else {
      message.sequence = 0
    }
    return message
  },
}

const baseAcknowledgement: object = {}

export const Acknowledgement = {
  encode(message: Acknowledgement, writer: Writer = Writer.create()): Writer {
    if (message.result !== undefined) {
      writer.uint32(170).bytes(message.result)
    }
    if (message.error !== undefined) {
      writer.uint32(178).string(message.error)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): Acknowledgement {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseAcknowledgement } as Acknowledgement
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 21:
          message.result = reader.bytes()
          break
        case 22:
          message.error = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Acknowledgement {
    const message = { ...baseAcknowledgement } as Acknowledgement
    if (object.result !== undefined && object.result !== null) {
      message.result = bytesFromBase64(object.result)
    }
    if (object.error !== undefined && object.error !== null) {
      message.error = String(object.error)
    } else {
      message.error = undefined
    }
    return message
  },

  toJSON(message: Acknowledgement): unknown {
    const obj: any = {}
    message.result !== undefined &&
      (obj.result =
        message.result !== undefined
          ? base64FromBytes(message.result)
          : undefined)
    message.error !== undefined && (obj.error = message.error)
    return obj
  },

  fromPartial(object: DeepPartial<Acknowledgement>): Acknowledgement {
    const message = { ...baseAcknowledgement } as Acknowledgement
    if (object.result !== undefined && object.result !== null) {
      message.result = object.result
    } else {
      message.result = undefined
    }
    if (object.error !== undefined && object.error !== null) {
      message.error = object.error
    } else {
      message.error = undefined
    }
    return message
  },
}

declare var self: any | undefined
declare var window: any | undefined
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis
  if (typeof self !== 'undefined') return self
  if (typeof window !== 'undefined') return window
  if (typeof global !== 'undefined') return global
  throw 'Unable to locate global object'
})()

const atob: (b64: string) => string =
  globalThis.atob ||
  ((b64) => globalThis.Buffer.from(b64, 'base64').toString('binary'))
function bytesFromBase64(b64: string): Uint8Array {
  const bin = atob(b64)
  const arr = new Uint8Array(bin.length)
  for (let i = 0; i < bin.length; ++i) {
    arr[i] = bin.charCodeAt(i)
  }
  return arr
}

const btoa: (bin: string) => string =
  globalThis.btoa ||
  ((bin) => globalThis.Buffer.from(bin, 'binary').toString('base64'))
function base64FromBytes(arr: Uint8Array): string {
  const bin: string[] = []
  for (let i = 0; i < arr.byteLength; ++i) {
    bin.push(String.fromCharCode(arr[i]))
  }
  return btoa(bin.join(''))
}

type Builtin = Date | Function | Uint8Array | string | number | undefined
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER')
  }
  return long.toNumber()
}

if (util.Long !== Long) {
  util.Long = Long as any
  configure()
}
