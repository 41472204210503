/* eslint-disable */
import { Reader, Writer } from 'protobufjs/minimal'
import { Coin } from '../../../cosmos/base/v1beta1/coin'
import {
  PageRequest,
  PageResponse,
} from '../../../cosmos/base/query/v1beta1/pagination'
import { Params, Metadata } from '../../../cosmos/bank/v1beta1/bank'

export const protobufPackage = 'cosmos.bank.v1beta1'

/** QueryBalanceRequest is the request type for the Query/Balance RPC method. */
export interface QueryBalanceRequest {
  /** address is the address to query balances for. */
  address: string
  /** denom is the coin denom to query balances for. */
  denom: string
}

/** QueryBalanceResponse is the response type for the Query/Balance RPC method. */
export interface QueryBalanceResponse {
  /** balance is the balance of the coin. */
  balance: Coin | undefined
}

/** QueryBalanceRequest is the request type for the Query/AllBalances RPC method. */
export interface QueryAllBalancesRequest {
  /** address is the address to query balances for. */
  address: string
  /** pagination defines an optional pagination for the request. */
  pagination: PageRequest | undefined
}

/**
 * QueryAllBalancesResponse is the response type for the Query/AllBalances RPC
 * method.
 */
export interface QueryAllBalancesResponse {
  /** balances is the balances of all the coins. */
  balances: Coin[]
  /** pagination defines the pagination in the response. */
  pagination: PageResponse | undefined
}

/**
 * QuerySpendableBalancesRequest defines the gRPC request structure for querying
 * an account's spendable balances.
 *
 * Since: cosmos-sdk 0.46
 */
export interface QuerySpendableBalancesRequest {
  /** address is the address to query spendable balances for. */
  address: string
  /** pagination defines an optional pagination for the request. */
  pagination: PageRequest | undefined
}

/**
 * QuerySpendableBalancesResponse defines the gRPC response structure for querying
 * an account's spendable balances.
 *
 * Since: cosmos-sdk 0.46
 */
export interface QuerySpendableBalancesResponse {
  /** balances is the spendable balances of all the coins. */
  balances: Coin[]
  /** pagination defines the pagination in the response. */
  pagination: PageResponse | undefined
}

/**
 * QueryTotalSupplyRequest is the request type for the Query/TotalSupply RPC
 * method.
 */
export interface QueryTotalSupplyRequest {
  /**
   * pagination defines an optional pagination for the request.
   *
   * Since: cosmos-sdk 0.43
   */
  pagination: PageRequest | undefined
}

/**
 * QueryTotalSupplyResponse is the response type for the Query/TotalSupply RPC
 * method
 */
export interface QueryTotalSupplyResponse {
  /** supply is the supply of the coins */
  supply: Coin[]
  /**
   * pagination defines the pagination in the response.
   *
   * Since: cosmos-sdk 0.43
   */
  pagination: PageResponse | undefined
}

/** QuerySupplyOfRequest is the request type for the Query/SupplyOf RPC method. */
export interface QuerySupplyOfRequest {
  /** denom is the coin denom to query balances for. */
  denom: string
}

/** QuerySupplyOfResponse is the response type for the Query/SupplyOf RPC method. */
export interface QuerySupplyOfResponse {
  /** amount is the supply of the coin. */
  amount: Coin | undefined
}

/** QueryParamsRequest defines the request type for querying x/bank parameters. */
export interface QueryParamsRequest {}

/** QueryParamsResponse defines the response type for querying x/bank parameters. */
export interface QueryParamsResponse {
  params: Params | undefined
}

/** QueryDenomsMetadataRequest is the request type for the Query/DenomsMetadata RPC method. */
export interface QueryDenomsMetadataRequest {
  /** pagination defines an optional pagination for the request. */
  pagination: PageRequest | undefined
}

/**
 * QueryDenomsMetadataResponse is the response type for the Query/DenomsMetadata RPC
 * method.
 */
export interface QueryDenomsMetadataResponse {
  /** metadata provides the client information for all the registered tokens. */
  metadatas: Metadata[]
  /** pagination defines the pagination in the response. */
  pagination: PageResponse | undefined
}

/** QueryDenomMetadataRequest is the request type for the Query/DenomMetadata RPC method. */
export interface QueryDenomMetadataRequest {
  /** denom is the coin denom to query the metadata for. */
  denom: string
}

/**
 * QueryDenomMetadataResponse is the response type for the Query/DenomMetadata RPC
 * method.
 */
export interface QueryDenomMetadataResponse {
  /** metadata describes and provides all the client information for the requested token. */
  metadata: Metadata | undefined
}

/**
 * QueryDenomOwnersRequest defines the request type for the DenomOwners RPC query,
 * which queries for a paginated set of all account holders of a particular
 * denomination.
 */
export interface QueryDenomOwnersRequest {
  /** denom defines the coin denomination to query all account holders for. */
  denom: string
  /** pagination defines an optional pagination for the request. */
  pagination: PageRequest | undefined
}

/**
 * DenomOwner defines structure representing an account that owns or holds a
 * particular denominated token. It contains the account address and account
 * balance of the denominated token.
 *
 * Since: cosmos-sdk 0.46
 */
export interface DenomOwner {
  /** address defines the address that owns a particular denomination. */
  address: string
  /** balance is the balance of the denominated coin for an account. */
  balance: Coin | undefined
}

/**
 * QueryDenomOwnersResponse defines the RPC response of a DenomOwners RPC query.
 *
 * Since: cosmos-sdk 0.46
 */
export interface QueryDenomOwnersResponse {
  denom_owners: DenomOwner[]
  /** pagination defines the pagination in the response. */
  pagination: PageResponse | undefined
}

const baseQueryBalanceRequest: object = { address: '', denom: '' }

export const QueryBalanceRequest = {
  encode(
    message: QueryBalanceRequest,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.address !== '') {
      writer.uint32(10).string(message.address)
    }
    if (message.denom !== '') {
      writer.uint32(18).string(message.denom)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): QueryBalanceRequest {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseQueryBalanceRequest } as QueryBalanceRequest
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.address = reader.string()
          break
        case 2:
          message.denom = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): QueryBalanceRequest {
    const message = { ...baseQueryBalanceRequest } as QueryBalanceRequest
    if (object.address !== undefined && object.address !== null) {
      message.address = String(object.address)
    } else {
      message.address = ''
    }
    if (object.denom !== undefined && object.denom !== null) {
      message.denom = String(object.denom)
    } else {
      message.denom = ''
    }
    return message
  },

  toJSON(message: QueryBalanceRequest): unknown {
    const obj: any = {}
    message.address !== undefined && (obj.address = message.address)
    message.denom !== undefined && (obj.denom = message.denom)
    return obj
  },

  fromPartial(object: DeepPartial<QueryBalanceRequest>): QueryBalanceRequest {
    const message = { ...baseQueryBalanceRequest } as QueryBalanceRequest
    if (object.address !== undefined && object.address !== null) {
      message.address = object.address
    } else {
      message.address = ''
    }
    if (object.denom !== undefined && object.denom !== null) {
      message.denom = object.denom
    } else {
      message.denom = ''
    }
    return message
  },
}

const baseQueryBalanceResponse: object = {}

export const QueryBalanceResponse = {
  encode(
    message: QueryBalanceResponse,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.balance !== undefined) {
      Coin.encode(message.balance, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): QueryBalanceResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseQueryBalanceResponse } as QueryBalanceResponse
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.balance = Coin.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): QueryBalanceResponse {
    const message = { ...baseQueryBalanceResponse } as QueryBalanceResponse
    if (object.balance !== undefined && object.balance !== null) {
      message.balance = Coin.fromJSON(object.balance)
    } else {
      message.balance = undefined
    }
    return message
  },

  toJSON(message: QueryBalanceResponse): unknown {
    const obj: any = {}
    message.balance !== undefined &&
      (obj.balance = message.balance ? Coin.toJSON(message.balance) : undefined)
    return obj
  },

  fromPartial(object: DeepPartial<QueryBalanceResponse>): QueryBalanceResponse {
    const message = { ...baseQueryBalanceResponse } as QueryBalanceResponse
    if (object.balance !== undefined && object.balance !== null) {
      message.balance = Coin.fromPartial(object.balance)
    } else {
      message.balance = undefined
    }
    return message
  },
}

const baseQueryAllBalancesRequest: object = { address: '' }

export const QueryAllBalancesRequest = {
  encode(
    message: QueryAllBalancesRequest,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.address !== '') {
      writer.uint32(10).string(message.address)
    }
    if (message.pagination !== undefined) {
      PageRequest.encode(message.pagination, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): QueryAllBalancesRequest {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = {
      ...baseQueryAllBalancesRequest,
    } as QueryAllBalancesRequest
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.address = reader.string()
          break
        case 2:
          message.pagination = PageRequest.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): QueryAllBalancesRequest {
    const message = {
      ...baseQueryAllBalancesRequest,
    } as QueryAllBalancesRequest
    if (object.address !== undefined && object.address !== null) {
      message.address = String(object.address)
    } else {
      message.address = ''
    }
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = PageRequest.fromJSON(object.pagination)
    } else {
      message.pagination = undefined
    }
    return message
  },

  toJSON(message: QueryAllBalancesRequest): unknown {
    const obj: any = {}
    message.address !== undefined && (obj.address = message.address)
    message.pagination !== undefined &&
      (obj.pagination = message.pagination
        ? PageRequest.toJSON(message.pagination)
        : undefined)
    return obj
  },

  fromPartial(
    object: DeepPartial<QueryAllBalancesRequest>
  ): QueryAllBalancesRequest {
    const message = {
      ...baseQueryAllBalancesRequest,
    } as QueryAllBalancesRequest
    if (object.address !== undefined && object.address !== null) {
      message.address = object.address
    } else {
      message.address = ''
    }
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = PageRequest.fromPartial(object.pagination)
    } else {
      message.pagination = undefined
    }
    return message
  },
}

const baseQueryAllBalancesResponse: object = {}

export const QueryAllBalancesResponse = {
  encode(
    message: QueryAllBalancesResponse,
    writer: Writer = Writer.create()
  ): Writer {
    for (const v of message.balances) {
      Coin.encode(v!, writer.uint32(10).fork()).ldelim()
    }
    if (message.pagination !== undefined) {
      PageResponse.encode(message.pagination, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(
    input: Reader | Uint8Array,
    length?: number
  ): QueryAllBalancesResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = {
      ...baseQueryAllBalancesResponse,
    } as QueryAllBalancesResponse
    message.balances = []
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.balances.push(Coin.decode(reader, reader.uint32()))
          break
        case 2:
          message.pagination = PageResponse.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): QueryAllBalancesResponse {
    const message = {
      ...baseQueryAllBalancesResponse,
    } as QueryAllBalancesResponse
    message.balances = []
    if (object.balances !== undefined && object.balances !== null) {
      for (const e of object.balances) {
        message.balances.push(Coin.fromJSON(e))
      }
    }
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = PageResponse.fromJSON(object.pagination)
    } else {
      message.pagination = undefined
    }
    return message
  },

  toJSON(message: QueryAllBalancesResponse): unknown {
    const obj: any = {}
    if (message.balances) {
      obj.balances = message.balances.map((e) =>
        e ? Coin.toJSON(e) : undefined
      )
    } else {
      obj.balances = []
    }
    message.pagination !== undefined &&
      (obj.pagination = message.pagination
        ? PageResponse.toJSON(message.pagination)
        : undefined)
    return obj
  },

  fromPartial(
    object: DeepPartial<QueryAllBalancesResponse>
  ): QueryAllBalancesResponse {
    const message = {
      ...baseQueryAllBalancesResponse,
    } as QueryAllBalancesResponse
    message.balances = []
    if (object.balances !== undefined && object.balances !== null) {
      for (const e of object.balances) {
        message.balances.push(Coin.fromPartial(e))
      }
    }
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = PageResponse.fromPartial(object.pagination)
    } else {
      message.pagination = undefined
    }
    return message
  },
}

const baseQuerySpendableBalancesRequest: object = { address: '' }

export const QuerySpendableBalancesRequest = {
  encode(
    message: QuerySpendableBalancesRequest,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.address !== '') {
      writer.uint32(10).string(message.address)
    }
    if (message.pagination !== undefined) {
      PageRequest.encode(message.pagination, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(
    input: Reader | Uint8Array,
    length?: number
  ): QuerySpendableBalancesRequest {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = {
      ...baseQuerySpendableBalancesRequest,
    } as QuerySpendableBalancesRequest
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.address = reader.string()
          break
        case 2:
          message.pagination = PageRequest.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): QuerySpendableBalancesRequest {
    const message = {
      ...baseQuerySpendableBalancesRequest,
    } as QuerySpendableBalancesRequest
    if (object.address !== undefined && object.address !== null) {
      message.address = String(object.address)
    } else {
      message.address = ''
    }
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = PageRequest.fromJSON(object.pagination)
    } else {
      message.pagination = undefined
    }
    return message
  },

  toJSON(message: QuerySpendableBalancesRequest): unknown {
    const obj: any = {}
    message.address !== undefined && (obj.address = message.address)
    message.pagination !== undefined &&
      (obj.pagination = message.pagination
        ? PageRequest.toJSON(message.pagination)
        : undefined)
    return obj
  },

  fromPartial(
    object: DeepPartial<QuerySpendableBalancesRequest>
  ): QuerySpendableBalancesRequest {
    const message = {
      ...baseQuerySpendableBalancesRequest,
    } as QuerySpendableBalancesRequest
    if (object.address !== undefined && object.address !== null) {
      message.address = object.address
    } else {
      message.address = ''
    }
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = PageRequest.fromPartial(object.pagination)
    } else {
      message.pagination = undefined
    }
    return message
  },
}

const baseQuerySpendableBalancesResponse: object = {}

export const QuerySpendableBalancesResponse = {
  encode(
    message: QuerySpendableBalancesResponse,
    writer: Writer = Writer.create()
  ): Writer {
    for (const v of message.balances) {
      Coin.encode(v!, writer.uint32(10).fork()).ldelim()
    }
    if (message.pagination !== undefined) {
      PageResponse.encode(message.pagination, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(
    input: Reader | Uint8Array,
    length?: number
  ): QuerySpendableBalancesResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = {
      ...baseQuerySpendableBalancesResponse,
    } as QuerySpendableBalancesResponse
    message.balances = []
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.balances.push(Coin.decode(reader, reader.uint32()))
          break
        case 2:
          message.pagination = PageResponse.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): QuerySpendableBalancesResponse {
    const message = {
      ...baseQuerySpendableBalancesResponse,
    } as QuerySpendableBalancesResponse
    message.balances = []
    if (object.balances !== undefined && object.balances !== null) {
      for (const e of object.balances) {
        message.balances.push(Coin.fromJSON(e))
      }
    }
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = PageResponse.fromJSON(object.pagination)
    } else {
      message.pagination = undefined
    }
    return message
  },

  toJSON(message: QuerySpendableBalancesResponse): unknown {
    const obj: any = {}
    if (message.balances) {
      obj.balances = message.balances.map((e) =>
        e ? Coin.toJSON(e) : undefined
      )
    } else {
      obj.balances = []
    }
    message.pagination !== undefined &&
      (obj.pagination = message.pagination
        ? PageResponse.toJSON(message.pagination)
        : undefined)
    return obj
  },

  fromPartial(
    object: DeepPartial<QuerySpendableBalancesResponse>
  ): QuerySpendableBalancesResponse {
    const message = {
      ...baseQuerySpendableBalancesResponse,
    } as QuerySpendableBalancesResponse
    message.balances = []
    if (object.balances !== undefined && object.balances !== null) {
      for (const e of object.balances) {
        message.balances.push(Coin.fromPartial(e))
      }
    }
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = PageResponse.fromPartial(object.pagination)
    } else {
      message.pagination = undefined
    }
    return message
  },
}

const baseQueryTotalSupplyRequest: object = {}

export const QueryTotalSupplyRequest = {
  encode(
    message: QueryTotalSupplyRequest,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.pagination !== undefined) {
      PageRequest.encode(message.pagination, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): QueryTotalSupplyRequest {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = {
      ...baseQueryTotalSupplyRequest,
    } as QueryTotalSupplyRequest
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.pagination = PageRequest.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): QueryTotalSupplyRequest {
    const message = {
      ...baseQueryTotalSupplyRequest,
    } as QueryTotalSupplyRequest
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = PageRequest.fromJSON(object.pagination)
    } else {
      message.pagination = undefined
    }
    return message
  },

  toJSON(message: QueryTotalSupplyRequest): unknown {
    const obj: any = {}
    message.pagination !== undefined &&
      (obj.pagination = message.pagination
        ? PageRequest.toJSON(message.pagination)
        : undefined)
    return obj
  },

  fromPartial(
    object: DeepPartial<QueryTotalSupplyRequest>
  ): QueryTotalSupplyRequest {
    const message = {
      ...baseQueryTotalSupplyRequest,
    } as QueryTotalSupplyRequest
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = PageRequest.fromPartial(object.pagination)
    } else {
      message.pagination = undefined
    }
    return message
  },
}

const baseQueryTotalSupplyResponse: object = {}

export const QueryTotalSupplyResponse = {
  encode(
    message: QueryTotalSupplyResponse,
    writer: Writer = Writer.create()
  ): Writer {
    for (const v of message.supply) {
      Coin.encode(v!, writer.uint32(10).fork()).ldelim()
    }
    if (message.pagination !== undefined) {
      PageResponse.encode(message.pagination, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(
    input: Reader | Uint8Array,
    length?: number
  ): QueryTotalSupplyResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = {
      ...baseQueryTotalSupplyResponse,
    } as QueryTotalSupplyResponse
    message.supply = []
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.supply.push(Coin.decode(reader, reader.uint32()))
          break
        case 2:
          message.pagination = PageResponse.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): QueryTotalSupplyResponse {
    const message = {
      ...baseQueryTotalSupplyResponse,
    } as QueryTotalSupplyResponse
    message.supply = []
    if (object.supply !== undefined && object.supply !== null) {
      for (const e of object.supply) {
        message.supply.push(Coin.fromJSON(e))
      }
    }
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = PageResponse.fromJSON(object.pagination)
    } else {
      message.pagination = undefined
    }
    return message
  },

  toJSON(message: QueryTotalSupplyResponse): unknown {
    const obj: any = {}
    if (message.supply) {
      obj.supply = message.supply.map((e) => (e ? Coin.toJSON(e) : undefined))
    } else {
      obj.supply = []
    }
    message.pagination !== undefined &&
      (obj.pagination = message.pagination
        ? PageResponse.toJSON(message.pagination)
        : undefined)
    return obj
  },

  fromPartial(
    object: DeepPartial<QueryTotalSupplyResponse>
  ): QueryTotalSupplyResponse {
    const message = {
      ...baseQueryTotalSupplyResponse,
    } as QueryTotalSupplyResponse
    message.supply = []
    if (object.supply !== undefined && object.supply !== null) {
      for (const e of object.supply) {
        message.supply.push(Coin.fromPartial(e))
      }
    }
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = PageResponse.fromPartial(object.pagination)
    } else {
      message.pagination = undefined
    }
    return message
  },
}

const baseQuerySupplyOfRequest: object = { denom: '' }

export const QuerySupplyOfRequest = {
  encode(
    message: QuerySupplyOfRequest,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.denom !== '') {
      writer.uint32(10).string(message.denom)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): QuerySupplyOfRequest {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseQuerySupplyOfRequest } as QuerySupplyOfRequest
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.denom = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): QuerySupplyOfRequest {
    const message = { ...baseQuerySupplyOfRequest } as QuerySupplyOfRequest
    if (object.denom !== undefined && object.denom !== null) {
      message.denom = String(object.denom)
    } else {
      message.denom = ''
    }
    return message
  },

  toJSON(message: QuerySupplyOfRequest): unknown {
    const obj: any = {}
    message.denom !== undefined && (obj.denom = message.denom)
    return obj
  },

  fromPartial(object: DeepPartial<QuerySupplyOfRequest>): QuerySupplyOfRequest {
    const message = { ...baseQuerySupplyOfRequest } as QuerySupplyOfRequest
    if (object.denom !== undefined && object.denom !== null) {
      message.denom = object.denom
    } else {
      message.denom = ''
    }
    return message
  },
}

const baseQuerySupplyOfResponse: object = {}

export const QuerySupplyOfResponse = {
  encode(
    message: QuerySupplyOfResponse,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.amount !== undefined) {
      Coin.encode(message.amount, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): QuerySupplyOfResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseQuerySupplyOfResponse } as QuerySupplyOfResponse
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.amount = Coin.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): QuerySupplyOfResponse {
    const message = { ...baseQuerySupplyOfResponse } as QuerySupplyOfResponse
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = Coin.fromJSON(object.amount)
    } else {
      message.amount = undefined
    }
    return message
  },

  toJSON(message: QuerySupplyOfResponse): unknown {
    const obj: any = {}
    message.amount !== undefined &&
      (obj.amount = message.amount ? Coin.toJSON(message.amount) : undefined)
    return obj
  },

  fromPartial(
    object: DeepPartial<QuerySupplyOfResponse>
  ): QuerySupplyOfResponse {
    const message = { ...baseQuerySupplyOfResponse } as QuerySupplyOfResponse
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = Coin.fromPartial(object.amount)
    } else {
      message.amount = undefined
    }
    return message
  },
}

const baseQueryParamsRequest: object = {}

export const QueryParamsRequest = {
  encode(_: QueryParamsRequest, writer: Writer = Writer.create()): Writer {
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): QueryParamsRequest {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseQueryParamsRequest } as QueryParamsRequest
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(_: any): QueryParamsRequest {
    const message = { ...baseQueryParamsRequest } as QueryParamsRequest
    return message
  },

  toJSON(_: QueryParamsRequest): unknown {
    const obj: any = {}
    return obj
  },

  fromPartial(_: DeepPartial<QueryParamsRequest>): QueryParamsRequest {
    const message = { ...baseQueryParamsRequest } as QueryParamsRequest
    return message
  },
}

const baseQueryParamsResponse: object = {}

export const QueryParamsResponse = {
  encode(
    message: QueryParamsResponse,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.params !== undefined) {
      Params.encode(message.params, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): QueryParamsResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseQueryParamsResponse } as QueryParamsResponse
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.params = Params.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): QueryParamsResponse {
    const message = { ...baseQueryParamsResponse } as QueryParamsResponse
    if (object.params !== undefined && object.params !== null) {
      message.params = Params.fromJSON(object.params)
    } else {
      message.params = undefined
    }
    return message
  },

  toJSON(message: QueryParamsResponse): unknown {
    const obj: any = {}
    message.params !== undefined &&
      (obj.params = message.params ? Params.toJSON(message.params) : undefined)
    return obj
  },

  fromPartial(object: DeepPartial<QueryParamsResponse>): QueryParamsResponse {
    const message = { ...baseQueryParamsResponse } as QueryParamsResponse
    if (object.params !== undefined && object.params !== null) {
      message.params = Params.fromPartial(object.params)
    } else {
      message.params = undefined
    }
    return message
  },
}

const baseQueryDenomsMetadataRequest: object = {}

export const QueryDenomsMetadataRequest = {
  encode(
    message: QueryDenomsMetadataRequest,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.pagination !== undefined) {
      PageRequest.encode(message.pagination, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(
    input: Reader | Uint8Array,
    length?: number
  ): QueryDenomsMetadataRequest {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = {
      ...baseQueryDenomsMetadataRequest,
    } as QueryDenomsMetadataRequest
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.pagination = PageRequest.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): QueryDenomsMetadataRequest {
    const message = {
      ...baseQueryDenomsMetadataRequest,
    } as QueryDenomsMetadataRequest
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = PageRequest.fromJSON(object.pagination)
    } else {
      message.pagination = undefined
    }
    return message
  },

  toJSON(message: QueryDenomsMetadataRequest): unknown {
    const obj: any = {}
    message.pagination !== undefined &&
      (obj.pagination = message.pagination
        ? PageRequest.toJSON(message.pagination)
        : undefined)
    return obj
  },

  fromPartial(
    object: DeepPartial<QueryDenomsMetadataRequest>
  ): QueryDenomsMetadataRequest {
    const message = {
      ...baseQueryDenomsMetadataRequest,
    } as QueryDenomsMetadataRequest
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = PageRequest.fromPartial(object.pagination)
    } else {
      message.pagination = undefined
    }
    return message
  },
}

const baseQueryDenomsMetadataResponse: object = {}

export const QueryDenomsMetadataResponse = {
  encode(
    message: QueryDenomsMetadataResponse,
    writer: Writer = Writer.create()
  ): Writer {
    for (const v of message.metadatas) {
      Metadata.encode(v!, writer.uint32(10).fork()).ldelim()
    }
    if (message.pagination !== undefined) {
      PageResponse.encode(message.pagination, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(
    input: Reader | Uint8Array,
    length?: number
  ): QueryDenomsMetadataResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = {
      ...baseQueryDenomsMetadataResponse,
    } as QueryDenomsMetadataResponse
    message.metadatas = []
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.metadatas.push(Metadata.decode(reader, reader.uint32()))
          break
        case 2:
          message.pagination = PageResponse.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): QueryDenomsMetadataResponse {
    const message = {
      ...baseQueryDenomsMetadataResponse,
    } as QueryDenomsMetadataResponse
    message.metadatas = []
    if (object.metadatas !== undefined && object.metadatas !== null) {
      for (const e of object.metadatas) {
        message.metadatas.push(Metadata.fromJSON(e))
      }
    }
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = PageResponse.fromJSON(object.pagination)
    } else {
      message.pagination = undefined
    }
    return message
  },

  toJSON(message: QueryDenomsMetadataResponse): unknown {
    const obj: any = {}
    if (message.metadatas) {
      obj.metadatas = message.metadatas.map((e) =>
        e ? Metadata.toJSON(e) : undefined
      )
    } else {
      obj.metadatas = []
    }
    message.pagination !== undefined &&
      (obj.pagination = message.pagination
        ? PageResponse.toJSON(message.pagination)
        : undefined)
    return obj
  },

  fromPartial(
    object: DeepPartial<QueryDenomsMetadataResponse>
  ): QueryDenomsMetadataResponse {
    const message = {
      ...baseQueryDenomsMetadataResponse,
    } as QueryDenomsMetadataResponse
    message.metadatas = []
    if (object.metadatas !== undefined && object.metadatas !== null) {
      for (const e of object.metadatas) {
        message.metadatas.push(Metadata.fromPartial(e))
      }
    }
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = PageResponse.fromPartial(object.pagination)
    } else {
      message.pagination = undefined
    }
    return message
  },
}

const baseQueryDenomMetadataRequest: object = { denom: '' }

export const QueryDenomMetadataRequest = {
  encode(
    message: QueryDenomMetadataRequest,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.denom !== '') {
      writer.uint32(10).string(message.denom)
    }
    return writer
  },

  decode(
    input: Reader | Uint8Array,
    length?: number
  ): QueryDenomMetadataRequest {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = {
      ...baseQueryDenomMetadataRequest,
    } as QueryDenomMetadataRequest
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.denom = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): QueryDenomMetadataRequest {
    const message = {
      ...baseQueryDenomMetadataRequest,
    } as QueryDenomMetadataRequest
    if (object.denom !== undefined && object.denom !== null) {
      message.denom = String(object.denom)
    } else {
      message.denom = ''
    }
    return message
  },

  toJSON(message: QueryDenomMetadataRequest): unknown {
    const obj: any = {}
    message.denom !== undefined && (obj.denom = message.denom)
    return obj
  },

  fromPartial(
    object: DeepPartial<QueryDenomMetadataRequest>
  ): QueryDenomMetadataRequest {
    const message = {
      ...baseQueryDenomMetadataRequest,
    } as QueryDenomMetadataRequest
    if (object.denom !== undefined && object.denom !== null) {
      message.denom = object.denom
    } else {
      message.denom = ''
    }
    return message
  },
}

const baseQueryDenomMetadataResponse: object = {}

export const QueryDenomMetadataResponse = {
  encode(
    message: QueryDenomMetadataResponse,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(
    input: Reader | Uint8Array,
    length?: number
  ): QueryDenomMetadataResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = {
      ...baseQueryDenomMetadataResponse,
    } as QueryDenomMetadataResponse
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.metadata = Metadata.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): QueryDenomMetadataResponse {
    const message = {
      ...baseQueryDenomMetadataResponse,
    } as QueryDenomMetadataResponse
    if (object.metadata !== undefined && object.metadata !== null) {
      message.metadata = Metadata.fromJSON(object.metadata)
    } else {
      message.metadata = undefined
    }
    return message
  },

  toJSON(message: QueryDenomMetadataResponse): unknown {
    const obj: any = {}
    message.metadata !== undefined &&
      (obj.metadata = message.metadata
        ? Metadata.toJSON(message.metadata)
        : undefined)
    return obj
  },

  fromPartial(
    object: DeepPartial<QueryDenomMetadataResponse>
  ): QueryDenomMetadataResponse {
    const message = {
      ...baseQueryDenomMetadataResponse,
    } as QueryDenomMetadataResponse
    if (object.metadata !== undefined && object.metadata !== null) {
      message.metadata = Metadata.fromPartial(object.metadata)
    } else {
      message.metadata = undefined
    }
    return message
  },
}

const baseQueryDenomOwnersRequest: object = { denom: '' }

export const QueryDenomOwnersRequest = {
  encode(
    message: QueryDenomOwnersRequest,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.denom !== '') {
      writer.uint32(10).string(message.denom)
    }
    if (message.pagination !== undefined) {
      PageRequest.encode(message.pagination, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): QueryDenomOwnersRequest {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = {
      ...baseQueryDenomOwnersRequest,
    } as QueryDenomOwnersRequest
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.denom = reader.string()
          break
        case 2:
          message.pagination = PageRequest.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): QueryDenomOwnersRequest {
    const message = {
      ...baseQueryDenomOwnersRequest,
    } as QueryDenomOwnersRequest
    if (object.denom !== undefined && object.denom !== null) {
      message.denom = String(object.denom)
    } else {
      message.denom = ''
    }
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = PageRequest.fromJSON(object.pagination)
    } else {
      message.pagination = undefined
    }
    return message
  },

  toJSON(message: QueryDenomOwnersRequest): unknown {
    const obj: any = {}
    message.denom !== undefined && (obj.denom = message.denom)
    message.pagination !== undefined &&
      (obj.pagination = message.pagination
        ? PageRequest.toJSON(message.pagination)
        : undefined)
    return obj
  },

  fromPartial(
    object: DeepPartial<QueryDenomOwnersRequest>
  ): QueryDenomOwnersRequest {
    const message = {
      ...baseQueryDenomOwnersRequest,
    } as QueryDenomOwnersRequest
    if (object.denom !== undefined && object.denom !== null) {
      message.denom = object.denom
    } else {
      message.denom = ''
    }
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = PageRequest.fromPartial(object.pagination)
    } else {
      message.pagination = undefined
    }
    return message
  },
}

const baseDenomOwner: object = { address: '' }

export const DenomOwner = {
  encode(message: DenomOwner, writer: Writer = Writer.create()): Writer {
    if (message.address !== '') {
      writer.uint32(10).string(message.address)
    }
    if (message.balance !== undefined) {
      Coin.encode(message.balance, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): DenomOwner {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseDenomOwner } as DenomOwner
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.address = reader.string()
          break
        case 2:
          message.balance = Coin.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): DenomOwner {
    const message = { ...baseDenomOwner } as DenomOwner
    if (object.address !== undefined && object.address !== null) {
      message.address = String(object.address)
    } else {
      message.address = ''
    }
    if (object.balance !== undefined && object.balance !== null) {
      message.balance = Coin.fromJSON(object.balance)
    } else {
      message.balance = undefined
    }
    return message
  },

  toJSON(message: DenomOwner): unknown {
    const obj: any = {}
    message.address !== undefined && (obj.address = message.address)
    message.balance !== undefined &&
      (obj.balance = message.balance ? Coin.toJSON(message.balance) : undefined)
    return obj
  },

  fromPartial(object: DeepPartial<DenomOwner>): DenomOwner {
    const message = { ...baseDenomOwner } as DenomOwner
    if (object.address !== undefined && object.address !== null) {
      message.address = object.address
    } else {
      message.address = ''
    }
    if (object.balance !== undefined && object.balance !== null) {
      message.balance = Coin.fromPartial(object.balance)
    } else {
      message.balance = undefined
    }
    return message
  },
}

const baseQueryDenomOwnersResponse: object = {}

export const QueryDenomOwnersResponse = {
  encode(
    message: QueryDenomOwnersResponse,
    writer: Writer = Writer.create()
  ): Writer {
    for (const v of message.denom_owners) {
      DenomOwner.encode(v!, writer.uint32(10).fork()).ldelim()
    }
    if (message.pagination !== undefined) {
      PageResponse.encode(message.pagination, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(
    input: Reader | Uint8Array,
    length?: number
  ): QueryDenomOwnersResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = {
      ...baseQueryDenomOwnersResponse,
    } as QueryDenomOwnersResponse
    message.denom_owners = []
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.denom_owners.push(DenomOwner.decode(reader, reader.uint32()))
          break
        case 2:
          message.pagination = PageResponse.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): QueryDenomOwnersResponse {
    const message = {
      ...baseQueryDenomOwnersResponse,
    } as QueryDenomOwnersResponse
    message.denom_owners = []
    if (object.denom_owners !== undefined && object.denom_owners !== null) {
      for (const e of object.denom_owners) {
        message.denom_owners.push(DenomOwner.fromJSON(e))
      }
    }
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = PageResponse.fromJSON(object.pagination)
    } else {
      message.pagination = undefined
    }
    return message
  },

  toJSON(message: QueryDenomOwnersResponse): unknown {
    const obj: any = {}
    if (message.denom_owners) {
      obj.denom_owners = message.denom_owners.map((e) =>
        e ? DenomOwner.toJSON(e) : undefined
      )
    } else {
      obj.denom_owners = []
    }
    message.pagination !== undefined &&
      (obj.pagination = message.pagination
        ? PageResponse.toJSON(message.pagination)
        : undefined)
    return obj
  },

  fromPartial(
    object: DeepPartial<QueryDenomOwnersResponse>
  ): QueryDenomOwnersResponse {
    const message = {
      ...baseQueryDenomOwnersResponse,
    } as QueryDenomOwnersResponse
    message.denom_owners = []
    if (object.denom_owners !== undefined && object.denom_owners !== null) {
      for (const e of object.denom_owners) {
        message.denom_owners.push(DenomOwner.fromPartial(e))
      }
    }
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = PageResponse.fromPartial(object.pagination)
    } else {
      message.pagination = undefined
    }
    return message
  },
}

/** Query defines the gRPC querier service. */
export interface Query {
  /** Balance queries the balance of a single coin for a single account. */
  Balance(request: QueryBalanceRequest): Promise<QueryBalanceResponse>
  /** AllBalances queries the balance of all coins for a single account. */
  AllBalances(
    request: QueryAllBalancesRequest
  ): Promise<QueryAllBalancesResponse>
  /**
   * SpendableBalances queries the spenable balance of all coins for a single
   * account.
   *
   * Since: cosmos-sdk 0.46
   */
  SpendableBalances(
    request: QuerySpendableBalancesRequest
  ): Promise<QuerySpendableBalancesResponse>
  /** TotalSupply queries the total supply of all coins. */
  TotalSupply(
    request: QueryTotalSupplyRequest
  ): Promise<QueryTotalSupplyResponse>
  /** SupplyOf queries the supply of a single coin. */
  SupplyOf(request: QuerySupplyOfRequest): Promise<QuerySupplyOfResponse>
  /** Params queries the parameters of x/bank module. */
  Params(request: QueryParamsRequest): Promise<QueryParamsResponse>
  /** DenomsMetadata queries the client metadata of a given coin denomination. */
  DenomMetadata(
    request: QueryDenomMetadataRequest
  ): Promise<QueryDenomMetadataResponse>
  /**
   * DenomsMetadata queries the client metadata for all registered coin
   * denominations.
   */
  DenomsMetadata(
    request: QueryDenomsMetadataRequest
  ): Promise<QueryDenomsMetadataResponse>
  /**
   * DenomOwners queries for all account addresses that own a particular token
   * denomination.
   *
   * Since: cosmos-sdk 0.46
   */
  DenomOwners(
    request: QueryDenomOwnersRequest
  ): Promise<QueryDenomOwnersResponse>
}

export class QueryClientImpl implements Query {
  private readonly rpc: Rpc
  constructor(rpc: Rpc) {
    this.rpc = rpc
  }
  Balance(request: QueryBalanceRequest): Promise<QueryBalanceResponse> {
    const data = QueryBalanceRequest.encode(request).finish()
    const promise = this.rpc.request(
      'cosmos.bank.v1beta1.Query',
      'Balance',
      data
    )
    return promise.then((data) => QueryBalanceResponse.decode(new Reader(data)))
  }

  AllBalances(
    request: QueryAllBalancesRequest
  ): Promise<QueryAllBalancesResponse> {
    const data = QueryAllBalancesRequest.encode(request).finish()
    const promise = this.rpc.request(
      'cosmos.bank.v1beta1.Query',
      'AllBalances',
      data
    )
    return promise.then((data) =>
      QueryAllBalancesResponse.decode(new Reader(data))
    )
  }

  SpendableBalances(
    request: QuerySpendableBalancesRequest
  ): Promise<QuerySpendableBalancesResponse> {
    const data = QuerySpendableBalancesRequest.encode(request).finish()
    const promise = this.rpc.request(
      'cosmos.bank.v1beta1.Query',
      'SpendableBalances',
      data
    )
    return promise.then((data) =>
      QuerySpendableBalancesResponse.decode(new Reader(data))
    )
  }

  TotalSupply(
    request: QueryTotalSupplyRequest
  ): Promise<QueryTotalSupplyResponse> {
    const data = QueryTotalSupplyRequest.encode(request).finish()
    const promise = this.rpc.request(
      'cosmos.bank.v1beta1.Query',
      'TotalSupply',
      data
    )
    return promise.then((data) =>
      QueryTotalSupplyResponse.decode(new Reader(data))
    )
  }

  SupplyOf(request: QuerySupplyOfRequest): Promise<QuerySupplyOfResponse> {
    const data = QuerySupplyOfRequest.encode(request).finish()
    const promise = this.rpc.request(
      'cosmos.bank.v1beta1.Query',
      'SupplyOf',
      data
    )
    return promise.then((data) =>
      QuerySupplyOfResponse.decode(new Reader(data))
    )
  }

  Params(request: QueryParamsRequest): Promise<QueryParamsResponse> {
    const data = QueryParamsRequest.encode(request).finish()
    const promise = this.rpc.request(
      'cosmos.bank.v1beta1.Query',
      'Params',
      data
    )
    return promise.then((data) => QueryParamsResponse.decode(new Reader(data)))
  }

  DenomMetadata(
    request: QueryDenomMetadataRequest
  ): Promise<QueryDenomMetadataResponse> {
    const data = QueryDenomMetadataRequest.encode(request).finish()
    const promise = this.rpc.request(
      'cosmos.bank.v1beta1.Query',
      'DenomMetadata',
      data
    )
    return promise.then((data) =>
      QueryDenomMetadataResponse.decode(new Reader(data))
    )
  }

  DenomsMetadata(
    request: QueryDenomsMetadataRequest
  ): Promise<QueryDenomsMetadataResponse> {
    const data = QueryDenomsMetadataRequest.encode(request).finish()
    const promise = this.rpc.request(
      'cosmos.bank.v1beta1.Query',
      'DenomsMetadata',
      data
    )
    return promise.then((data) =>
      QueryDenomsMetadataResponse.decode(new Reader(data))
    )
  }

  DenomOwners(
    request: QueryDenomOwnersRequest
  ): Promise<QueryDenomOwnersResponse> {
    const data = QueryDenomOwnersRequest.encode(request).finish()
    const promise = this.rpc.request(
      'cosmos.bank.v1beta1.Query',
      'DenomOwners',
      data
    )
    return promise.then((data) =>
      QueryDenomOwnersResponse.decode(new Reader(data))
    )
  }
}

interface Rpc {
  request(
    service: string,
    method: string,
    data: Uint8Array
  ): Promise<Uint8Array>
}

type Builtin = Date | Function | Uint8Array | string | number | undefined
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>
