/* eslint-disable */
import { Writer, Reader } from 'protobufjs/minimal'

export const protobufPackage = 'ibc.applications.transfer.v1'

/**
 * DenomTrace contains the base denomination for ICS20 fungible tokens and the
 * source tracing information path.
 */
export interface DenomTrace {
  /**
   * path defines the chain of port/channel identifiers used for tracing the
   * source of the fungible token.
   */
  path: string
  /** base denomination of the relayed fungible token. */
  base_denom: string
}

/**
 * Params defines the set of IBC transfer parameters.
 * NOTE: To prevent a single token from being transferred, set the
 * TransfersEnabled parameter to true and then set the bank module's SendEnabled
 * parameter for the denomination to false.
 */
export interface Params {
  /**
   * send_enabled enables or disables all cross-chain token transfers from this
   * chain.
   */
  send_enabled: boolean
  /**
   * receive_enabled enables or disables all cross-chain token transfers to this
   * chain.
   */
  receive_enabled: boolean
}

const baseDenomTrace: object = { path: '', base_denom: '' }

export const DenomTrace = {
  encode(message: DenomTrace, writer: Writer = Writer.create()): Writer {
    if (message.path !== '') {
      writer.uint32(10).string(message.path)
    }
    if (message.base_denom !== '') {
      writer.uint32(18).string(message.base_denom)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): DenomTrace {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseDenomTrace } as DenomTrace
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.path = reader.string()
          break
        case 2:
          message.base_denom = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): DenomTrace {
    const message = { ...baseDenomTrace } as DenomTrace
    if (object.path !== undefined && object.path !== null) {
      message.path = String(object.path)
    } else {
      message.path = ''
    }
    if (object.base_denom !== undefined && object.base_denom !== null) {
      message.base_denom = String(object.base_denom)
    } else {
      message.base_denom = ''
    }
    return message
  },

  toJSON(message: DenomTrace): unknown {
    const obj: any = {}
    message.path !== undefined && (obj.path = message.path)
    message.base_denom !== undefined && (obj.base_denom = message.base_denom)
    return obj
  },

  fromPartial(object: DeepPartial<DenomTrace>): DenomTrace {
    const message = { ...baseDenomTrace } as DenomTrace
    if (object.path !== undefined && object.path !== null) {
      message.path = object.path
    } else {
      message.path = ''
    }
    if (object.base_denom !== undefined && object.base_denom !== null) {
      message.base_denom = object.base_denom
    } else {
      message.base_denom = ''
    }
    return message
  },
}

const baseParams: object = { send_enabled: false, receive_enabled: false }

export const Params = {
  encode(message: Params, writer: Writer = Writer.create()): Writer {
    if (message.send_enabled === true) {
      writer.uint32(8).bool(message.send_enabled)
    }
    if (message.receive_enabled === true) {
      writer.uint32(16).bool(message.receive_enabled)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): Params {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseParams } as Params
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.send_enabled = reader.bool()
          break
        case 2:
          message.receive_enabled = reader.bool()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Params {
    const message = { ...baseParams } as Params
    if (object.send_enabled !== undefined && object.send_enabled !== null) {
      message.send_enabled = Boolean(object.send_enabled)
    } else {
      message.send_enabled = false
    }
    if (
      object.receive_enabled !== undefined &&
      object.receive_enabled !== null
    ) {
      message.receive_enabled = Boolean(object.receive_enabled)
    } else {
      message.receive_enabled = false
    }
    return message
  },

  toJSON(message: Params): unknown {
    const obj: any = {}
    message.send_enabled !== undefined &&
      (obj.send_enabled = message.send_enabled)
    message.receive_enabled !== undefined &&
      (obj.receive_enabled = message.receive_enabled)
    return obj
  },

  fromPartial(object: DeepPartial<Params>): Params {
    const message = { ...baseParams } as Params
    if (object.send_enabled !== undefined && object.send_enabled !== null) {
      message.send_enabled = object.send_enabled
    } else {
      message.send_enabled = false
    }
    if (
      object.receive_enabled !== undefined &&
      object.receive_enabled !== null
    ) {
      message.receive_enabled = object.receive_enabled
    } else {
      message.receive_enabled = false
    }
    return message
  },
}

type Builtin = Date | Function | Uint8Array | string | number | undefined
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>
