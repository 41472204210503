/* eslint-disable */
import { Timestamp } from '../../../google/protobuf/timestamp'
import { Any } from '../../../google/protobuf/any'
import { Writer, Reader } from 'protobufjs/minimal'

export const protobufPackage = 'cosmos.authz.v1beta1'

/** Since: cosmos-sdk 0.43 */

/**
 * GenericAuthorization gives the grantee unrestricted permissions to execute
 * the provided method on behalf of the granter's account.
 */
export interface GenericAuthorization {
  /** Msg, identified by it's type URL, to grant unrestricted permissions to execute */
  msg: string
}

/**
 * Grant gives permissions to execute
 * the provide method with expiration time.
 */
export interface Grant {
  authorization: Any | undefined
  /**
   * time when the grant will expire and will be pruned. If null, then the grant
   * doesn't have a time expiration (other conditions  in `authorization`
   * may apply to invalidate the grant)
   */
  expiration: Date | undefined
}

/**
 * GrantAuthorization extends a grant with both the addresses of the grantee and granter.
 * It is used in genesis.proto and query.proto
 */
export interface GrantAuthorization {
  granter: string
  grantee: string
  authorization: Any | undefined
  expiration: Date | undefined
}

/** GrantQueueItem contains the list of TypeURL of a sdk.Msg. */
export interface GrantQueueItem {
  /** msg_type_urls contains the list of TypeURL of a sdk.Msg. */
  msg_type_urls: string[]
}

const baseGenericAuthorization: object = { msg: '' }

export const GenericAuthorization = {
  encode(
    message: GenericAuthorization,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.msg !== '') {
      writer.uint32(10).string(message.msg)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): GenericAuthorization {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseGenericAuthorization } as GenericAuthorization
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.msg = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GenericAuthorization {
    const message = { ...baseGenericAuthorization } as GenericAuthorization
    if (object.msg !== undefined && object.msg !== null) {
      message.msg = String(object.msg)
    } else {
      message.msg = ''
    }
    return message
  },

  toJSON(message: GenericAuthorization): unknown {
    const obj: any = {}
    message.msg !== undefined && (obj.msg = message.msg)
    return obj
  },

  fromPartial(object: DeepPartial<GenericAuthorization>): GenericAuthorization {
    const message = { ...baseGenericAuthorization } as GenericAuthorization
    if (object.msg !== undefined && object.msg !== null) {
      message.msg = object.msg
    } else {
      message.msg = ''
    }
    return message
  },
}

const baseGrant: object = {}

export const Grant = {
  encode(message: Grant, writer: Writer = Writer.create()): Writer {
    if (message.authorization !== undefined) {
      Any.encode(message.authorization, writer.uint32(10).fork()).ldelim()
    }
    if (message.expiration !== undefined) {
      Timestamp.encode(
        toTimestamp(message.expiration),
        writer.uint32(18).fork()
      ).ldelim()
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): Grant {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseGrant } as Grant
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.authorization = Any.decode(reader, reader.uint32())
          break
        case 2:
          message.expiration = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          )
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Grant {
    const message = { ...baseGrant } as Grant
    if (object.authorization !== undefined && object.authorization !== null) {
      message.authorization = Any.fromJSON(object.authorization)
    } else {
      message.authorization = undefined
    }
    if (object.expiration !== undefined && object.expiration !== null) {
      message.expiration = fromJsonTimestamp(object.expiration)
    } else {
      message.expiration = undefined
    }
    return message
  },

  toJSON(message: Grant): unknown {
    const obj: any = {}
    message.authorization !== undefined &&
      (obj.authorization = message.authorization
        ? Any.toJSON(message.authorization)
        : undefined)
    message.expiration !== undefined &&
      (obj.expiration =
        message.expiration !== undefined
          ? message.expiration.toISOString()
          : null)
    return obj
  },

  fromPartial(object: DeepPartial<Grant>): Grant {
    const message = { ...baseGrant } as Grant
    if (object.authorization !== undefined && object.authorization !== null) {
      message.authorization = Any.fromPartial(object.authorization)
    } else {
      message.authorization = undefined
    }
    if (object.expiration !== undefined && object.expiration !== null) {
      message.expiration = object.expiration
    } else {
      message.expiration = undefined
    }
    return message
  },
}

const baseGrantAuthorization: object = { granter: '', grantee: '' }

export const GrantAuthorization = {
  encode(
    message: GrantAuthorization,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.granter !== '') {
      writer.uint32(10).string(message.granter)
    }
    if (message.grantee !== '') {
      writer.uint32(18).string(message.grantee)
    }
    if (message.authorization !== undefined) {
      Any.encode(message.authorization, writer.uint32(26).fork()).ldelim()
    }
    if (message.expiration !== undefined) {
      Timestamp.encode(
        toTimestamp(message.expiration),
        writer.uint32(34).fork()
      ).ldelim()
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): GrantAuthorization {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseGrantAuthorization } as GrantAuthorization
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.granter = reader.string()
          break
        case 2:
          message.grantee = reader.string()
          break
        case 3:
          message.authorization = Any.decode(reader, reader.uint32())
          break
        case 4:
          message.expiration = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          )
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GrantAuthorization {
    const message = { ...baseGrantAuthorization } as GrantAuthorization
    if (object.granter !== undefined && object.granter !== null) {
      message.granter = String(object.granter)
    } else {
      message.granter = ''
    }
    if (object.grantee !== undefined && object.grantee !== null) {
      message.grantee = String(object.grantee)
    } else {
      message.grantee = ''
    }
    if (object.authorization !== undefined && object.authorization !== null) {
      message.authorization = Any.fromJSON(object.authorization)
    } else {
      message.authorization = undefined
    }
    if (object.expiration !== undefined && object.expiration !== null) {
      message.expiration = fromJsonTimestamp(object.expiration)
    } else {
      message.expiration = undefined
    }
    return message
  },

  toJSON(message: GrantAuthorization): unknown {
    const obj: any = {}
    message.granter !== undefined && (obj.granter = message.granter)
    message.grantee !== undefined && (obj.grantee = message.grantee)
    message.authorization !== undefined &&
      (obj.authorization = message.authorization
        ? Any.toJSON(message.authorization)
        : undefined)
    message.expiration !== undefined &&
      (obj.expiration =
        message.expiration !== undefined
          ? message.expiration.toISOString()
          : null)
    return obj
  },

  fromPartial(object: DeepPartial<GrantAuthorization>): GrantAuthorization {
    const message = { ...baseGrantAuthorization } as GrantAuthorization
    if (object.granter !== undefined && object.granter !== null) {
      message.granter = object.granter
    } else {
      message.granter = ''
    }
    if (object.grantee !== undefined && object.grantee !== null) {
      message.grantee = object.grantee
    } else {
      message.grantee = ''
    }
    if (object.authorization !== undefined && object.authorization !== null) {
      message.authorization = Any.fromPartial(object.authorization)
    } else {
      message.authorization = undefined
    }
    if (object.expiration !== undefined && object.expiration !== null) {
      message.expiration = object.expiration
    } else {
      message.expiration = undefined
    }
    return message
  },
}

const baseGrantQueueItem: object = { msg_type_urls: '' }

export const GrantQueueItem = {
  encode(message: GrantQueueItem, writer: Writer = Writer.create()): Writer {
    for (const v of message.msg_type_urls) {
      writer.uint32(10).string(v!)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): GrantQueueItem {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseGrantQueueItem } as GrantQueueItem
    message.msg_type_urls = []
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.msg_type_urls.push(reader.string())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): GrantQueueItem {
    const message = { ...baseGrantQueueItem } as GrantQueueItem
    message.msg_type_urls = []
    if (object.msg_type_urls !== undefined && object.msg_type_urls !== null) {
      for (const e of object.msg_type_urls) {
        message.msg_type_urls.push(String(e))
      }
    }
    return message
  },

  toJSON(message: GrantQueueItem): unknown {
    const obj: any = {}
    if (message.msg_type_urls) {
      obj.msg_type_urls = message.msg_type_urls.map((e) => e)
    } else {
      obj.msg_type_urls = []
    }
    return obj
  },

  fromPartial(object: DeepPartial<GrantQueueItem>): GrantQueueItem {
    const message = { ...baseGrantQueueItem } as GrantQueueItem
    message.msg_type_urls = []
    if (object.msg_type_urls !== undefined && object.msg_type_urls !== null) {
      for (const e of object.msg_type_urls) {
        message.msg_type_urls.push(e)
      }
    }
    return message
  },
}

type Builtin = Date | Function | Uint8Array | string | number | undefined
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000
  const nanos = (date.getTime() % 1_000) * 1_000_000
  return { seconds, nanos }
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000
  millis += t.nanos / 1_000_000
  return new Date(millis)
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o
  } else if (typeof o === 'string') {
    return new Date(o)
  } else {
    return fromTimestamp(Timestamp.fromJSON(o))
  }
}
