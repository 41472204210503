/* eslint-disable */
import { Coin } from '../../../cosmos/base/v1beta1/coin'
import { Writer, Reader } from 'protobufjs/minimal'

export const protobufPackage = 'cosmos.bank.v1beta1'

/** Params defines the parameters for the bank module. */
export interface Params {
  send_enabled: SendEnabled[]
  default_send_enabled: boolean
}

/**
 * SendEnabled maps coin denom to a send_enabled status (whether a denom is
 * sendable).
 */
export interface SendEnabled {
  denom: string
  enabled: boolean
}

/** Input models transaction input. */
export interface Input {
  address: string
  coins: Coin[]
}

/** Output models transaction outputs. */
export interface Output {
  address: string
  coins: Coin[]
}

/**
 * Supply represents a struct that passively keeps track of the total supply
 * amounts in the network.
 * This message is deprecated now that supply is indexed by denom.
 *
 * @deprecated
 */
export interface Supply {
  total: Coin[]
}

/**
 * DenomUnit represents a struct that describes a given
 * denomination unit of the basic token.
 */
export interface DenomUnit {
  /** denom represents the string name of the given denom unit (e.g uatom). */
  denom: string
  /**
   * exponent represents power of 10 exponent that one must
   * raise the base_denom to in order to equal the given DenomUnit's denom
   * 1 denom = 10^exponent base_denom
   * (e.g. with a base_denom of uatom, one can create a DenomUnit of 'atom' with
   * exponent = 6, thus: 1 atom = 10^6 uatom).
   */
  exponent: number
  /** aliases is a list of string aliases for the given denom */
  aliases: string[]
}

/**
 * Metadata represents a struct that describes
 * a basic token.
 */
export interface Metadata {
  description: string
  /** denom_units represents the list of DenomUnit's for a given coin */
  denom_units: DenomUnit[]
  /** base represents the base denom (should be the DenomUnit with exponent = 0). */
  base: string
  /**
   * display indicates the suggested denom that should be
   * displayed in clients.
   */
  display: string
  /**
   * name defines the name of the token (eg: Cosmos Atom)
   *
   * Since: cosmos-sdk 0.43
   */
  name: string
  /**
   * symbol is the token symbol usually shown on exchanges (eg: ATOM). This can
   * be the same as the display.
   *
   * Since: cosmos-sdk 0.43
   */
  symbol: string
  /**
   * URI to a document (on or off-chain) that contains additional information. Optional.
   *
   * Since: cosmos-sdk 0.46
   */
  uri: string
  /**
   * URIHash is a sha256 hash of a document pointed by URI. It's used to verify that
   * the document didn't change. Optional.
   *
   * Since: cosmos-sdk 0.46
   */
  uri_hash: string
}

const baseParams: object = { default_send_enabled: false }

export const Params = {
  encode(message: Params, writer: Writer = Writer.create()): Writer {
    for (const v of message.send_enabled) {
      SendEnabled.encode(v!, writer.uint32(10).fork()).ldelim()
    }
    if (message.default_send_enabled === true) {
      writer.uint32(16).bool(message.default_send_enabled)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): Params {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseParams } as Params
    message.send_enabled = []
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.send_enabled.push(SendEnabled.decode(reader, reader.uint32()))
          break
        case 2:
          message.default_send_enabled = reader.bool()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Params {
    const message = { ...baseParams } as Params
    message.send_enabled = []
    if (object.send_enabled !== undefined && object.send_enabled !== null) {
      for (const e of object.send_enabled) {
        message.send_enabled.push(SendEnabled.fromJSON(e))
      }
    }
    if (
      object.default_send_enabled !== undefined &&
      object.default_send_enabled !== null
    ) {
      message.default_send_enabled = Boolean(object.default_send_enabled)
    } else {
      message.default_send_enabled = false
    }
    return message
  },

  toJSON(message: Params): unknown {
    const obj: any = {}
    if (message.send_enabled) {
      obj.send_enabled = message.send_enabled.map((e) =>
        e ? SendEnabled.toJSON(e) : undefined
      )
    } else {
      obj.send_enabled = []
    }
    message.default_send_enabled !== undefined &&
      (obj.default_send_enabled = message.default_send_enabled)
    return obj
  },

  fromPartial(object: DeepPartial<Params>): Params {
    const message = { ...baseParams } as Params
    message.send_enabled = []
    if (object.send_enabled !== undefined && object.send_enabled !== null) {
      for (const e of object.send_enabled) {
        message.send_enabled.push(SendEnabled.fromPartial(e))
      }
    }
    if (
      object.default_send_enabled !== undefined &&
      object.default_send_enabled !== null
    ) {
      message.default_send_enabled = object.default_send_enabled
    } else {
      message.default_send_enabled = false
    }
    return message
  },
}

const baseSendEnabled: object = { denom: '', enabled: false }

export const SendEnabled = {
  encode(message: SendEnabled, writer: Writer = Writer.create()): Writer {
    if (message.denom !== '') {
      writer.uint32(10).string(message.denom)
    }
    if (message.enabled === true) {
      writer.uint32(16).bool(message.enabled)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): SendEnabled {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseSendEnabled } as SendEnabled
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.denom = reader.string()
          break
        case 2:
          message.enabled = reader.bool()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): SendEnabled {
    const message = { ...baseSendEnabled } as SendEnabled
    if (object.denom !== undefined && object.denom !== null) {
      message.denom = String(object.denom)
    } else {
      message.denom = ''
    }
    if (object.enabled !== undefined && object.enabled !== null) {
      message.enabled = Boolean(object.enabled)
    } else {
      message.enabled = false
    }
    return message
  },

  toJSON(message: SendEnabled): unknown {
    const obj: any = {}
    message.denom !== undefined && (obj.denom = message.denom)
    message.enabled !== undefined && (obj.enabled = message.enabled)
    return obj
  },

  fromPartial(object: DeepPartial<SendEnabled>): SendEnabled {
    const message = { ...baseSendEnabled } as SendEnabled
    if (object.denom !== undefined && object.denom !== null) {
      message.denom = object.denom
    } else {
      message.denom = ''
    }
    if (object.enabled !== undefined && object.enabled !== null) {
      message.enabled = object.enabled
    } else {
      message.enabled = false
    }
    return message
  },
}

const baseInput: object = { address: '' }

export const Input = {
  encode(message: Input, writer: Writer = Writer.create()): Writer {
    if (message.address !== '') {
      writer.uint32(10).string(message.address)
    }
    for (const v of message.coins) {
      Coin.encode(v!, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): Input {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseInput } as Input
    message.coins = []
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.address = reader.string()
          break
        case 2:
          message.coins.push(Coin.decode(reader, reader.uint32()))
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Input {
    const message = { ...baseInput } as Input
    message.coins = []
    if (object.address !== undefined && object.address !== null) {
      message.address = String(object.address)
    } else {
      message.address = ''
    }
    if (object.coins !== undefined && object.coins !== null) {
      for (const e of object.coins) {
        message.coins.push(Coin.fromJSON(e))
      }
    }
    return message
  },

  toJSON(message: Input): unknown {
    const obj: any = {}
    message.address !== undefined && (obj.address = message.address)
    if (message.coins) {
      obj.coins = message.coins.map((e) => (e ? Coin.toJSON(e) : undefined))
    } else {
      obj.coins = []
    }
    return obj
  },

  fromPartial(object: DeepPartial<Input>): Input {
    const message = { ...baseInput } as Input
    message.coins = []
    if (object.address !== undefined && object.address !== null) {
      message.address = object.address
    } else {
      message.address = ''
    }
    if (object.coins !== undefined && object.coins !== null) {
      for (const e of object.coins) {
        message.coins.push(Coin.fromPartial(e))
      }
    }
    return message
  },
}

const baseOutput: object = { address: '' }

export const Output = {
  encode(message: Output, writer: Writer = Writer.create()): Writer {
    if (message.address !== '') {
      writer.uint32(10).string(message.address)
    }
    for (const v of message.coins) {
      Coin.encode(v!, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): Output {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseOutput } as Output
    message.coins = []
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.address = reader.string()
          break
        case 2:
          message.coins.push(Coin.decode(reader, reader.uint32()))
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Output {
    const message = { ...baseOutput } as Output
    message.coins = []
    if (object.address !== undefined && object.address !== null) {
      message.address = String(object.address)
    } else {
      message.address = ''
    }
    if (object.coins !== undefined && object.coins !== null) {
      for (const e of object.coins) {
        message.coins.push(Coin.fromJSON(e))
      }
    }
    return message
  },

  toJSON(message: Output): unknown {
    const obj: any = {}
    message.address !== undefined && (obj.address = message.address)
    if (message.coins) {
      obj.coins = message.coins.map((e) => (e ? Coin.toJSON(e) : undefined))
    } else {
      obj.coins = []
    }
    return obj
  },

  fromPartial(object: DeepPartial<Output>): Output {
    const message = { ...baseOutput } as Output
    message.coins = []
    if (object.address !== undefined && object.address !== null) {
      message.address = object.address
    } else {
      message.address = ''
    }
    if (object.coins !== undefined && object.coins !== null) {
      for (const e of object.coins) {
        message.coins.push(Coin.fromPartial(e))
      }
    }
    return message
  },
}

const baseSupply: object = {}

export const Supply = {
  encode(message: Supply, writer: Writer = Writer.create()): Writer {
    for (const v of message.total) {
      Coin.encode(v!, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): Supply {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseSupply } as Supply
    message.total = []
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.total.push(Coin.decode(reader, reader.uint32()))
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Supply {
    const message = { ...baseSupply } as Supply
    message.total = []
    if (object.total !== undefined && object.total !== null) {
      for (const e of object.total) {
        message.total.push(Coin.fromJSON(e))
      }
    }
    return message
  },

  toJSON(message: Supply): unknown {
    const obj: any = {}
    if (message.total) {
      obj.total = message.total.map((e) => (e ? Coin.toJSON(e) : undefined))
    } else {
      obj.total = []
    }
    return obj
  },

  fromPartial(object: DeepPartial<Supply>): Supply {
    const message = { ...baseSupply } as Supply
    message.total = []
    if (object.total !== undefined && object.total !== null) {
      for (const e of object.total) {
        message.total.push(Coin.fromPartial(e))
      }
    }
    return message
  },
}

const baseDenomUnit: object = { denom: '', exponent: 0, aliases: '' }

export const DenomUnit = {
  encode(message: DenomUnit, writer: Writer = Writer.create()): Writer {
    if (message.denom !== '') {
      writer.uint32(10).string(message.denom)
    }
    if (message.exponent !== 0) {
      writer.uint32(16).uint32(message.exponent)
    }
    for (const v of message.aliases) {
      writer.uint32(26).string(v!)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): DenomUnit {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseDenomUnit } as DenomUnit
    message.aliases = []
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.denom = reader.string()
          break
        case 2:
          message.exponent = reader.uint32()
          break
        case 3:
          message.aliases.push(reader.string())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): DenomUnit {
    const message = { ...baseDenomUnit } as DenomUnit
    message.aliases = []
    if (object.denom !== undefined && object.denom !== null) {
      message.denom = String(object.denom)
    } else {
      message.denom = ''
    }
    if (object.exponent !== undefined && object.exponent !== null) {
      message.exponent = Number(object.exponent)
    } else {
      message.exponent = 0
    }
    if (object.aliases !== undefined && object.aliases !== null) {
      for (const e of object.aliases) {
        message.aliases.push(String(e))
      }
    }
    return message
  },

  toJSON(message: DenomUnit): unknown {
    const obj: any = {}
    message.denom !== undefined && (obj.denom = message.denom)
    message.exponent !== undefined && (obj.exponent = message.exponent)
    if (message.aliases) {
      obj.aliases = message.aliases.map((e) => e)
    } else {
      obj.aliases = []
    }
    return obj
  },

  fromPartial(object: DeepPartial<DenomUnit>): DenomUnit {
    const message = { ...baseDenomUnit } as DenomUnit
    message.aliases = []
    if (object.denom !== undefined && object.denom !== null) {
      message.denom = object.denom
    } else {
      message.denom = ''
    }
    if (object.exponent !== undefined && object.exponent !== null) {
      message.exponent = object.exponent
    } else {
      message.exponent = 0
    }
    if (object.aliases !== undefined && object.aliases !== null) {
      for (const e of object.aliases) {
        message.aliases.push(e)
      }
    }
    return message
  },
}

const baseMetadata: object = {
  description: '',
  base: '',
  display: '',
  name: '',
  symbol: '',
  uri: '',
  uri_hash: '',
}

export const Metadata = {
  encode(message: Metadata, writer: Writer = Writer.create()): Writer {
    if (message.description !== '') {
      writer.uint32(10).string(message.description)
    }
    for (const v of message.denom_units) {
      DenomUnit.encode(v!, writer.uint32(18).fork()).ldelim()
    }
    if (message.base !== '') {
      writer.uint32(26).string(message.base)
    }
    if (message.display !== '') {
      writer.uint32(34).string(message.display)
    }
    if (message.name !== '') {
      writer.uint32(42).string(message.name)
    }
    if (message.symbol !== '') {
      writer.uint32(50).string(message.symbol)
    }
    if (message.uri !== '') {
      writer.uint32(58).string(message.uri)
    }
    if (message.uri_hash !== '') {
      writer.uint32(66).string(message.uri_hash)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): Metadata {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseMetadata } as Metadata
    message.denom_units = []
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.description = reader.string()
          break
        case 2:
          message.denom_units.push(DenomUnit.decode(reader, reader.uint32()))
          break
        case 3:
          message.base = reader.string()
          break
        case 4:
          message.display = reader.string()
          break
        case 5:
          message.name = reader.string()
          break
        case 6:
          message.symbol = reader.string()
          break
        case 7:
          message.uri = reader.string()
          break
        case 8:
          message.uri_hash = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Metadata {
    const message = { ...baseMetadata } as Metadata
    message.denom_units = []
    if (object.description !== undefined && object.description !== null) {
      message.description = String(object.description)
    } else {
      message.description = ''
    }
    if (object.denom_units !== undefined && object.denom_units !== null) {
      for (const e of object.denom_units) {
        message.denom_units.push(DenomUnit.fromJSON(e))
      }
    }
    if (object.base !== undefined && object.base !== null) {
      message.base = String(object.base)
    } else {
      message.base = ''
    }
    if (object.display !== undefined && object.display !== null) {
      message.display = String(object.display)
    } else {
      message.display = ''
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = String(object.name)
    } else {
      message.name = ''
    }
    if (object.symbol !== undefined && object.symbol !== null) {
      message.symbol = String(object.symbol)
    } else {
      message.symbol = ''
    }
    if (object.uri !== undefined && object.uri !== null) {
      message.uri = String(object.uri)
    } else {
      message.uri = ''
    }
    if (object.uri_hash !== undefined && object.uri_hash !== null) {
      message.uri_hash = String(object.uri_hash)
    } else {
      message.uri_hash = ''
    }
    return message
  },

  toJSON(message: Metadata): unknown {
    const obj: any = {}
    message.description !== undefined && (obj.description = message.description)
    if (message.denom_units) {
      obj.denom_units = message.denom_units.map((e) =>
        e ? DenomUnit.toJSON(e) : undefined
      )
    } else {
      obj.denom_units = []
    }
    message.base !== undefined && (obj.base = message.base)
    message.display !== undefined && (obj.display = message.display)
    message.name !== undefined && (obj.name = message.name)
    message.symbol !== undefined && (obj.symbol = message.symbol)
    message.uri !== undefined && (obj.uri = message.uri)
    message.uri_hash !== undefined && (obj.uri_hash = message.uri_hash)
    return obj
  },

  fromPartial(object: DeepPartial<Metadata>): Metadata {
    const message = { ...baseMetadata } as Metadata
    message.denom_units = []
    if (object.description !== undefined && object.description !== null) {
      message.description = object.description
    } else {
      message.description = ''
    }
    if (object.denom_units !== undefined && object.denom_units !== null) {
      for (const e of object.denom_units) {
        message.denom_units.push(DenomUnit.fromPartial(e))
      }
    }
    if (object.base !== undefined && object.base !== null) {
      message.base = object.base
    } else {
      message.base = ''
    }
    if (object.display !== undefined && object.display !== null) {
      message.display = object.display
    } else {
      message.display = ''
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = object.name
    } else {
      message.name = ''
    }
    if (object.symbol !== undefined && object.symbol !== null) {
      message.symbol = object.symbol
    } else {
      message.symbol = ''
    }
    if (object.uri !== undefined && object.uri !== null) {
      message.uri = object.uri
    } else {
      message.uri = ''
    }
    if (object.uri_hash !== undefined && object.uri_hash !== null) {
      message.uri_hash = object.uri_hash
    } else {
      message.uri_hash = ''
    }
    return message
  },
}

type Builtin = Date | Function | Uint8Array | string | number | undefined
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>
