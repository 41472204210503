/* eslint-disable */
import { Reader, Writer } from 'protobufjs/minimal'

export const protobufPackage = 'cosmos.slashing.v1beta1'

/** MsgUnjail defines the Msg/Unjail request type */
export interface MsgUnjail {
  validator_addr: string
}

/** MsgUnjailResponse defines the Msg/Unjail response type */
export interface MsgUnjailResponse {}

const baseMsgUnjail: object = { validator_addr: '' }

export const MsgUnjail = {
  encode(message: MsgUnjail, writer: Writer = Writer.create()): Writer {
    if (message.validator_addr !== '') {
      writer.uint32(10).string(message.validator_addr)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): MsgUnjail {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseMsgUnjail } as MsgUnjail
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.validator_addr = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): MsgUnjail {
    const message = { ...baseMsgUnjail } as MsgUnjail
    if (object.validator_addr !== undefined && object.validator_addr !== null) {
      message.validator_addr = String(object.validator_addr)
    } else {
      message.validator_addr = ''
    }
    return message
  },

  toJSON(message: MsgUnjail): unknown {
    const obj: any = {}
    message.validator_addr !== undefined &&
      (obj.validator_addr = message.validator_addr)
    return obj
  },

  fromPartial(object: DeepPartial<MsgUnjail>): MsgUnjail {
    const message = { ...baseMsgUnjail } as MsgUnjail
    if (object.validator_addr !== undefined && object.validator_addr !== null) {
      message.validator_addr = object.validator_addr
    } else {
      message.validator_addr = ''
    }
    return message
  },
}

const baseMsgUnjailResponse: object = {}

export const MsgUnjailResponse = {
  encode(_: MsgUnjailResponse, writer: Writer = Writer.create()): Writer {
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): MsgUnjailResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseMsgUnjailResponse } as MsgUnjailResponse
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(_: any): MsgUnjailResponse {
    const message = { ...baseMsgUnjailResponse } as MsgUnjailResponse
    return message
  },

  toJSON(_: MsgUnjailResponse): unknown {
    const obj: any = {}
    return obj
  },

  fromPartial(_: DeepPartial<MsgUnjailResponse>): MsgUnjailResponse {
    const message = { ...baseMsgUnjailResponse } as MsgUnjailResponse
    return message
  },
}

/** Msg defines the slashing Msg service. */
export interface Msg {
  /**
   * Unjail defines a method for unjailing a jailed validator, thus returning
   * them into the bonded validator set, so they can begin receiving provisions
   * and rewards again.
   */
  Unjail(request: MsgUnjail): Promise<MsgUnjailResponse>
}

export class MsgClientImpl implements Msg {
  private readonly rpc: Rpc
  constructor(rpc: Rpc) {
    this.rpc = rpc
  }
  Unjail(request: MsgUnjail): Promise<MsgUnjailResponse> {
    const data = MsgUnjail.encode(request).finish()
    const promise = this.rpc.request(
      'cosmos.slashing.v1beta1.Msg',
      'Unjail',
      data
    )
    return promise.then((data) => MsgUnjailResponse.decode(new Reader(data)))
  }
}

interface Rpc {
  request(
    service: string,
    method: string,
    data: Uint8Array
  ): Promise<Uint8Array>
}

type Builtin = Date | Function | Uint8Array | string | number | undefined
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>
