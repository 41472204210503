/* eslint-disable */
import { Writer, Reader } from 'protobufjs/minimal'

export const protobufPackage = 'cosmos.base.v1beta1'

/**
 * Coin defines a token with a denomination and an amount.
 *
 * NOTE: The amount field is an Int which implements the custom method
 * signatures required by gogoproto.
 */
export interface Coin {
  denom: string
  amount: string
}

/**
 * DecCoin defines a token with a denomination and a decimal amount.
 *
 * NOTE: The amount field is an Dec which implements the custom method
 * signatures required by gogoproto.
 */
export interface DecCoin {
  denom: string
  amount: string
}

/** IntProto defines a Protobuf wrapper around an Int object. */
export interface IntProto {
  int: string
}

/** DecProto defines a Protobuf wrapper around a Dec object. */
export interface DecProto {
  dec: string
}

const baseCoin: object = { denom: '', amount: '' }

export const Coin = {
  encode(message: Coin, writer: Writer = Writer.create()): Writer {
    if (message.denom !== '') {
      writer.uint32(10).string(message.denom)
    }
    if (message.amount !== '') {
      writer.uint32(18).string(message.amount)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): Coin {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseCoin } as Coin
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.denom = reader.string()
          break
        case 2:
          message.amount = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Coin {
    const message = { ...baseCoin } as Coin
    if (object.denom !== undefined && object.denom !== null) {
      message.denom = String(object.denom)
    } else {
      message.denom = ''
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = String(object.amount)
    } else {
      message.amount = ''
    }
    return message
  },

  toJSON(message: Coin): unknown {
    const obj: any = {}
    message.denom !== undefined && (obj.denom = message.denom)
    message.amount !== undefined && (obj.amount = message.amount)
    return obj
  },

  fromPartial(object: DeepPartial<Coin>): Coin {
    const message = { ...baseCoin } as Coin
    if (object.denom !== undefined && object.denom !== null) {
      message.denom = object.denom
    } else {
      message.denom = ''
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = object.amount
    } else {
      message.amount = ''
    }
    return message
  },
}

const baseDecCoin: object = { denom: '', amount: '' }

export const DecCoin = {
  encode(message: DecCoin, writer: Writer = Writer.create()): Writer {
    if (message.denom !== '') {
      writer.uint32(10).string(message.denom)
    }
    if (message.amount !== '') {
      writer.uint32(18).string(message.amount)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): DecCoin {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseDecCoin } as DecCoin
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.denom = reader.string()
          break
        case 2:
          message.amount = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): DecCoin {
    const message = { ...baseDecCoin } as DecCoin
    if (object.denom !== undefined && object.denom !== null) {
      message.denom = String(object.denom)
    } else {
      message.denom = ''
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = String(object.amount)
    } else {
      message.amount = ''
    }
    return message
  },

  toJSON(message: DecCoin): unknown {
    const obj: any = {}
    message.denom !== undefined && (obj.denom = message.denom)
    message.amount !== undefined && (obj.amount = message.amount)
    return obj
  },

  fromPartial(object: DeepPartial<DecCoin>): DecCoin {
    const message = { ...baseDecCoin } as DecCoin
    if (object.denom !== undefined && object.denom !== null) {
      message.denom = object.denom
    } else {
      message.denom = ''
    }
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = object.amount
    } else {
      message.amount = ''
    }
    return message
  },
}

const baseIntProto: object = { int: '' }

export const IntProto = {
  encode(message: IntProto, writer: Writer = Writer.create()): Writer {
    if (message.int !== '') {
      writer.uint32(10).string(message.int)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): IntProto {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseIntProto } as IntProto
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.int = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): IntProto {
    const message = { ...baseIntProto } as IntProto
    if (object.int !== undefined && object.int !== null) {
      message.int = String(object.int)
    } else {
      message.int = ''
    }
    return message
  },

  toJSON(message: IntProto): unknown {
    const obj: any = {}
    message.int !== undefined && (obj.int = message.int)
    return obj
  },

  fromPartial(object: DeepPartial<IntProto>): IntProto {
    const message = { ...baseIntProto } as IntProto
    if (object.int !== undefined && object.int !== null) {
      message.int = object.int
    } else {
      message.int = ''
    }
    return message
  },
}

const baseDecProto: object = { dec: '' }

export const DecProto = {
  encode(message: DecProto, writer: Writer = Writer.create()): Writer {
    if (message.dec !== '') {
      writer.uint32(10).string(message.dec)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): DecProto {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseDecProto } as DecProto
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.dec = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): DecProto {
    const message = { ...baseDecProto } as DecProto
    if (object.dec !== undefined && object.dec !== null) {
      message.dec = String(object.dec)
    } else {
      message.dec = ''
    }
    return message
  },

  toJSON(message: DecProto): unknown {
    const obj: any = {}
    message.dec !== undefined && (obj.dec = message.dec)
    return obj
  },

  fromPartial(object: DeepPartial<DecProto>): DecProto {
    const message = { ...baseDecProto } as DecProto
    if (object.dec !== undefined && object.dec !== null) {
      message.dec = object.dec
    } else {
      message.dec = ''
    }
    return message
  },
}

type Builtin = Date | Function | Uint8Array | string | number | undefined
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>
