/* eslint-disable */
import * as Long from 'long'
import { util, configure, Writer, Reader } from 'protobufjs/minimal'

export const protobufPackage = 'tendermint.crypto'

export interface Proof {
  total: number
  index: number
  leaf_hash: Uint8Array
  aunts: Uint8Array[]
}

export interface ValueOp {
  /** Encoded in ProofOp.Key. */
  key: Uint8Array
  /** To encode in ProofOp.Data */
  proof: Proof | undefined
}

export interface DominoOp {
  key: string
  input: string
  output: string
}

/**
 * ProofOp defines an operation used for calculating Merkle root
 * The data could be arbitrary format, providing nessecary data
 * for example neighbouring node hash
 */
export interface ProofOp {
  type: string
  key: Uint8Array
  data: Uint8Array
}

/** ProofOps is Merkle proof defined by the list of ProofOps */
export interface ProofOps {
  ops: ProofOp[]
}

const baseProof: object = { total: 0, index: 0 }

export const Proof = {
  encode(message: Proof, writer: Writer = Writer.create()): Writer {
    if (message.total !== 0) {
      writer.uint32(8).int64(message.total)
    }
    if (message.index !== 0) {
      writer.uint32(16).int64(message.index)
    }
    if (message.leaf_hash.length !== 0) {
      writer.uint32(26).bytes(message.leaf_hash)
    }
    for (const v of message.aunts) {
      writer.uint32(34).bytes(v!)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): Proof {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseProof } as Proof
    message.aunts = []
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.total = longToNumber(reader.int64() as Long)
          break
        case 2:
          message.index = longToNumber(reader.int64() as Long)
          break
        case 3:
          message.leaf_hash = reader.bytes()
          break
        case 4:
          message.aunts.push(reader.bytes())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Proof {
    const message = { ...baseProof } as Proof
    message.aunts = []
    if (object.total !== undefined && object.total !== null) {
      message.total = Number(object.total)
    } else {
      message.total = 0
    }
    if (object.index !== undefined && object.index !== null) {
      message.index = Number(object.index)
    } else {
      message.index = 0
    }
    if (object.leaf_hash !== undefined && object.leaf_hash !== null) {
      message.leaf_hash = bytesFromBase64(object.leaf_hash)
    }
    if (object.aunts !== undefined && object.aunts !== null) {
      for (const e of object.aunts) {
        message.aunts.push(bytesFromBase64(e))
      }
    }
    return message
  },

  toJSON(message: Proof): unknown {
    const obj: any = {}
    message.total !== undefined && (obj.total = message.total)
    message.index !== undefined && (obj.index = message.index)
    message.leaf_hash !== undefined &&
      (obj.leaf_hash = base64FromBytes(
        message.leaf_hash !== undefined ? message.leaf_hash : new Uint8Array()
      ))
    if (message.aunts) {
      obj.aunts = message.aunts.map((e) =>
        base64FromBytes(e !== undefined ? e : new Uint8Array())
      )
    } else {
      obj.aunts = []
    }
    return obj
  },

  fromPartial(object: DeepPartial<Proof>): Proof {
    const message = { ...baseProof } as Proof
    message.aunts = []
    if (object.total !== undefined && object.total !== null) {
      message.total = object.total
    } else {
      message.total = 0
    }
    if (object.index !== undefined && object.index !== null) {
      message.index = object.index
    } else {
      message.index = 0
    }
    if (object.leaf_hash !== undefined && object.leaf_hash !== null) {
      message.leaf_hash = object.leaf_hash
    } else {
      message.leaf_hash = new Uint8Array()
    }
    if (object.aunts !== undefined && object.aunts !== null) {
      for (const e of object.aunts) {
        message.aunts.push(e)
      }
    }
    return message
  },
}

const baseValueOp: object = {}

export const ValueOp = {
  encode(message: ValueOp, writer: Writer = Writer.create()): Writer {
    if (message.key.length !== 0) {
      writer.uint32(10).bytes(message.key)
    }
    if (message.proof !== undefined) {
      Proof.encode(message.proof, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): ValueOp {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseValueOp } as ValueOp
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.key = reader.bytes()
          break
        case 2:
          message.proof = Proof.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): ValueOp {
    const message = { ...baseValueOp } as ValueOp
    if (object.key !== undefined && object.key !== null) {
      message.key = bytesFromBase64(object.key)
    }
    if (object.proof !== undefined && object.proof !== null) {
      message.proof = Proof.fromJSON(object.proof)
    } else {
      message.proof = undefined
    }
    return message
  },

  toJSON(message: ValueOp): unknown {
    const obj: any = {}
    message.key !== undefined &&
      (obj.key = base64FromBytes(
        message.key !== undefined ? message.key : new Uint8Array()
      ))
    message.proof !== undefined &&
      (obj.proof = message.proof ? Proof.toJSON(message.proof) : undefined)
    return obj
  },

  fromPartial(object: DeepPartial<ValueOp>): ValueOp {
    const message = { ...baseValueOp } as ValueOp
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key
    } else {
      message.key = new Uint8Array()
    }
    if (object.proof !== undefined && object.proof !== null) {
      message.proof = Proof.fromPartial(object.proof)
    } else {
      message.proof = undefined
    }
    return message
  },
}

const baseDominoOp: object = { key: '', input: '', output: '' }

export const DominoOp = {
  encode(message: DominoOp, writer: Writer = Writer.create()): Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key)
    }
    if (message.input !== '') {
      writer.uint32(18).string(message.input)
    }
    if (message.output !== '') {
      writer.uint32(26).string(message.output)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): DominoOp {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseDominoOp } as DominoOp
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string()
          break
        case 2:
          message.input = reader.string()
          break
        case 3:
          message.output = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): DominoOp {
    const message = { ...baseDominoOp } as DominoOp
    if (object.key !== undefined && object.key !== null) {
      message.key = String(object.key)
    } else {
      message.key = ''
    }
    if (object.input !== undefined && object.input !== null) {
      message.input = String(object.input)
    } else {
      message.input = ''
    }
    if (object.output !== undefined && object.output !== null) {
      message.output = String(object.output)
    } else {
      message.output = ''
    }
    return message
  },

  toJSON(message: DominoOp): unknown {
    const obj: any = {}
    message.key !== undefined && (obj.key = message.key)
    message.input !== undefined && (obj.input = message.input)
    message.output !== undefined && (obj.output = message.output)
    return obj
  },

  fromPartial(object: DeepPartial<DominoOp>): DominoOp {
    const message = { ...baseDominoOp } as DominoOp
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key
    } else {
      message.key = ''
    }
    if (object.input !== undefined && object.input !== null) {
      message.input = object.input
    } else {
      message.input = ''
    }
    if (object.output !== undefined && object.output !== null) {
      message.output = object.output
    } else {
      message.output = ''
    }
    return message
  },
}

const baseProofOp: object = { type: '' }

export const ProofOp = {
  encode(message: ProofOp, writer: Writer = Writer.create()): Writer {
    if (message.type !== '') {
      writer.uint32(10).string(message.type)
    }
    if (message.key.length !== 0) {
      writer.uint32(18).bytes(message.key)
    }
    if (message.data.length !== 0) {
      writer.uint32(26).bytes(message.data)
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): ProofOp {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseProofOp } as ProofOp
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.type = reader.string()
          break
        case 2:
          message.key = reader.bytes()
          break
        case 3:
          message.data = reader.bytes()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): ProofOp {
    const message = { ...baseProofOp } as ProofOp
    if (object.type !== undefined && object.type !== null) {
      message.type = String(object.type)
    } else {
      message.type = ''
    }
    if (object.key !== undefined && object.key !== null) {
      message.key = bytesFromBase64(object.key)
    }
    if (object.data !== undefined && object.data !== null) {
      message.data = bytesFromBase64(object.data)
    }
    return message
  },

  toJSON(message: ProofOp): unknown {
    const obj: any = {}
    message.type !== undefined && (obj.type = message.type)
    message.key !== undefined &&
      (obj.key = base64FromBytes(
        message.key !== undefined ? message.key : new Uint8Array()
      ))
    message.data !== undefined &&
      (obj.data = base64FromBytes(
        message.data !== undefined ? message.data : new Uint8Array()
      ))
    return obj
  },

  fromPartial(object: DeepPartial<ProofOp>): ProofOp {
    const message = { ...baseProofOp } as ProofOp
    if (object.type !== undefined && object.type !== null) {
      message.type = object.type
    } else {
      message.type = ''
    }
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key
    } else {
      message.key = new Uint8Array()
    }
    if (object.data !== undefined && object.data !== null) {
      message.data = object.data
    } else {
      message.data = new Uint8Array()
    }
    return message
  },
}

const baseProofOps: object = {}

export const ProofOps = {
  encode(message: ProofOps, writer: Writer = Writer.create()): Writer {
    for (const v of message.ops) {
      ProofOp.encode(v!, writer.uint32(10).fork()).ldelim()
    }
    return writer
  },

  decode(input: Reader | Uint8Array, length?: number): ProofOps {
    const reader = input instanceof Uint8Array ? new Reader(input) : input
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseProofOps } as ProofOps
    message.ops = []
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.ops.push(ProofOp.decode(reader, reader.uint32()))
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): ProofOps {
    const message = { ...baseProofOps } as ProofOps
    message.ops = []
    if (object.ops !== undefined && object.ops !== null) {
      for (const e of object.ops) {
        message.ops.push(ProofOp.fromJSON(e))
      }
    }
    return message
  },

  toJSON(message: ProofOps): unknown {
    const obj: any = {}
    if (message.ops) {
      obj.ops = message.ops.map((e) => (e ? ProofOp.toJSON(e) : undefined))
    } else {
      obj.ops = []
    }
    return obj
  },

  fromPartial(object: DeepPartial<ProofOps>): ProofOps {
    const message = { ...baseProofOps } as ProofOps
    message.ops = []
    if (object.ops !== undefined && object.ops !== null) {
      for (const e of object.ops) {
        message.ops.push(ProofOp.fromPartial(e))
      }
    }
    return message
  },
}

declare var self: any | undefined
declare var window: any | undefined
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis
  if (typeof self !== 'undefined') return self
  if (typeof window !== 'undefined') return window
  if (typeof global !== 'undefined') return global
  throw 'Unable to locate global object'
})()

const atob: (b64: string) => string =
  globalThis.atob ||
  ((b64) => globalThis.Buffer.from(b64, 'base64').toString('binary'))
function bytesFromBase64(b64: string): Uint8Array {
  const bin = atob(b64)
  const arr = new Uint8Array(bin.length)
  for (let i = 0; i < bin.length; ++i) {
    arr[i] = bin.charCodeAt(i)
  }
  return arr
}

const btoa: (bin: string) => string =
  globalThis.btoa ||
  ((bin) => globalThis.Buffer.from(bin, 'binary').toString('base64'))
function base64FromBytes(arr: Uint8Array): string {
  const bin: string[] = []
  for (let i = 0; i < arr.byteLength; ++i) {
    bin.push(String.fromCharCode(arr[i]))
  }
  return btoa(bin.join(''))
}

type Builtin = Date | Function | Uint8Array | string | number | undefined
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER')
  }
  return long.toNumber()
}

if (util.Long !== Long) {
  util.Long = Long as any
  configure()
}
